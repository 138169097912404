import { Field } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import Policies from './Policies';
import { imageUrls } from '@/config/constants/keys';
import {
  UI_DATE_FORMAT,
  UI_DATE_LOWER_FORMAT
} from '@/config/constants/static';
import { getDateLocale, getFilterLocaleValues, getImagePath, hasEnabledFeature, hasRestrictedRole, isSingleRoleOnlyUser, isdvirOnly } from '@/utils/utils';
import { number, string } from 'yup';
import DefectList from './defectList';
// import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const UploadList = lazy(() =>
  import(`@/components/modules/DocumentUploadList`)
);
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const beBaseUrl = window?.$environment?.BE_BASE_URL;
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  cancelAction
}) => {
  const { i18n } = useTranslation();
  const {
    Styled,
    workOrders,
    vehicleMechanicList,
    partsList,
    trailerList,
    vehicleList,
    logBook,
    getDocumentToken,
    viewReport,
    setOpenPhoto,
    setModalObjectPhoto
  } = customProps;

  const editData = workOrders?.getData?.workOrderEditDto;
  const [toggleParts, setToggleParts] = useState(null);
  const [uploadList, setUploadList] = useState({ currentDocs: [] });
  const [lengthOfParts, setlengthOfParts] = useState(null);
  const isDVIROnlyFlagEnabled = isdvirOnly()
  const [isDocumentAvailable, setIsDocumentAvailable] = useState(true);

  useEffect(() => {

    setToggleParts([...partsList]);

    let length = partsList.filter((item) => {
      return item.isDisabled;
    }).length;

    let finalLength = partsList.length - length;
    setlengthOfParts(finalLength);
  }, [partsList]);
  useEffect(() => {
    let woAddObj = {
      id: null,
      userId: null,
      vehicleIdPub: null,
      trailerIdPub: null,
      companyId: null,
      workOrderIdPub: null,
      file: null,
      errorMessage: null,
      editUpload: null,
      type: {
        name: 'WORK_ORDER_IMAGE',
        order: 600,
        fullName: 'Work Order Image',
        documentGroup: 'WORK_ORDER'
      },
      name: null,
      url: null,
      uploaded: null,
      expiration_date: null,
      doctype: 'WORK_ORDER'
    };
    if (workOrders?.documentsList) {
      let a = [...workOrders?.documentsList];
      const isLoaded = (currentValue) => currentValue?.id !== null;
      if (a.every(isLoaded)) a.push(woAddObj);
      setUploadList({
        currentDocs: [...a]
      });
      const noDocuments = a.some(o => o.id)
      setIsDocumentAvailable(noDocuments)
    }
  }, [workOrders?.documentsList]);

  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };

  useEffect(() => {
    let data = workOrders?.getData?.workOrderEditDto;
    let elegs = document.getElementById('mechanic_slect_wo_gs');
    let ele = document.getElementById('mechanic_slect_wo');

    if (data?.state) {
      setStatusArray(
        statusArray.map((key, index) => {
          if (data?.state === 'SCHEDULED' && index === 0) {
            return { ...key, selected: true };
          } else if (data?.state === 'APPROVED' && index === 3) {
            return { ...key, selected: true };
          } else if (key.value === data?.state) {
            return { ...key, selected: true };
          } else {
            return { ...key, selected: false };
          }
        })
      );
      if (data?.state !== 'COMPLETED') {
        elegs && elegs.classList.remove('mechanic_disable');
        ele && ele.classList.remove('mechanic_disable');
      } else if (data?.state === 'COMPLETED' && window.$environment.CURRENT_LAYOUT === 'NewUi')
        elegs && elegs.classList.add('mechanic_disable');
      else if (data?.state === 'COMPLETED' && window.$environment.CURRENT_LAYOUT !== 'NewUi')
        ele && ele.classList.add('mechanic_disable');
    } else {
      setStatusArray(
        statusArray.map((key, index) => {
          if (index === 0) {
            return { ...key, selected: true };
          } else {
            return key;
          }
        })
      );
    }
  }, [workOrders?.getData?.workOrderEditDto]);
  const [statusValue, setStatusValue] = useState(null);
  const [statusArray, setStatusArray] = useState([
    {
      value: 'NOT_STARTED',
      selected: false,
      img: getImagePath(imageUrls.notstartedIcons),
      selectedImg: getImagePath(imageUrls.notstartedorangeIcons)
    },
    {
      value: 'AWAITING_PARTS',
      selected: false,
      img: getImagePath(imageUrls.awaitinggreyIcons),
      selectedImg: getImagePath(imageUrls.awaitingorangeIcons)
    },
    {
      value: 'IN_PROGRESS',
      selected: false,
      img: getImagePath(imageUrls.progressgreyIcons),
      selectedImg: getImagePath(imageUrls.progressorangeIcons)
    },
    {
      value: 'COMPLETED',
      selected: false,
      img: getImagePath(imageUrls.completegreyIcons),
      selectedImg: getImagePath(imageUrls.completeorangeIcons)
    },
    {
      value: 'CLOSED',
      selected: false,
      img: getImagePath(imageUrls.closedgreyIcons),
      selectedImg: getImagePath(imageUrls.closedOrangeIcons)
    }
  ]);
  const handleClickStatus = (value, index) => {
    setStatusArray(
      statusArray.map((key, i) => {
        if (i === index) return { ...key, selected: true };
        else return { ...key, selected: false };
      })
    );
    setStatusValue(value);
    handleEdit(value, { field: 'state' });
  };
  const [partsArray, setPartsArray] = useState([]);

  const addPartsClicked = () => {
    if (partsArray.length < lengthOfParts) {
      const findIndex = toggleParts.findIndex((element) => !element.isDisabled);
      let c = [...toggleParts];
      c[findIndex] = { ...c[findIndex], isDisabled: true };
      setToggleParts(c);

      let temp = [...partsArray];
      let obj = { ...toggleParts[findIndex] };
      obj.orderedQty = 1;
      temp.push(obj);
      setPartsArray([...temp]);
      handleEdit([...temp], { field: 'partsArray' });
    }
  };

  const removePartsClicked = (items, index) => {
    const findIndex = toggleParts.findIndex(
      (element) => element.value === items.value
    );

    let c = [...toggleParts];
    c[findIndex] = { ...c[findIndex], isDisabled: false };
    setToggleParts(c);

    let temp = [...partsArray];
    let a = temp.filter((item) => item.value !== items.value);
    setPartsArray([...a]);
    handleEdit([...a], { field: 'partsArray' });
  };
  const unitType = [
    {
      label: 'Vehicle',
      value: 'VEHICLE'
    },
    {
      label: 'Trailer',
      value: 'TRAILER'
    }
  ];

  const typeRepair = [
    {
      label: 'Repair',
      value: 'REPAIR'
    },
    {
      label: 'Safety Repair',
      value: 'SAFETY_REPAIR'
    },
    {
      label: 'Maintainence',
      value: 'MAINTENANCE'
    }
  ];
  const handlePartsChange = (items, part) => {
    const findIndex = partsArray.findIndex(
      (element) => element.value === items.value
    );

    let c = [...partsArray];
    c[findIndex] = { ...part };
    setPartsArray(c);
    handleEdit([...c], { field: 'partsArray' });

    const findIndexToggleEnable = toggleParts.findIndex(
      (element) => element.value === items.value
    );
    const findIndexToggleDisable = toggleParts.findIndex(
      (element) => element.value === part.value
    );

    let d = [...toggleParts];
    d[findIndexToggleEnable] = {
      ...d[findIndexToggleEnable],
      isDisabled: false
    };
    d[findIndexToggleDisable] = {
      ...d[findIndexToggleDisable],
      isDisabled: true
    };
    setToggleParts(d);
  };

  const handleCountChange = (items, value) => {
    const findIndex = partsArray.findIndex(
      (element) => element.value === items.value
    );

    let c = [...partsArray];
    c[findIndex] = { ...c[findIndex], orderedQty: value };
    setPartsArray(c);
    handleEdit([...c], { field: 'partsArray' });
  };

  const findFirstEnabled = () => {
    const found = toggleParts.find((element) => element.isDisabled === false);
    return found;
  };

  const commonFields = {
    unitType: string().required(i18n.t('common.fieldRequiredMessage')),
    type: string().required(i18n.t('common.fieldRequiredMessage')),
    beginDate: string().required(i18n.t('common.fieldRequiredMessage')),
    endDate: string().required(i18n.t('common.fieldRequiredMessage'))
  };

  const vehicleField = {
    mileage: number()
      .typeError(i18n.t('common.validNumberMessage'))
      .positive(i18n.t('common.positiveNumberMessage'))
      .required(i18n.t('common.fieldRequiredMessage'))
  };

  useEffect(() => {
    if (values?.unitType?.value === 'VEHICLE') {
      customProps.setValidationlist({
        ...commonFields,
        ...vehicleField
      });
    } else {
      customProps.setValidationlist({
        ...commonFields
      });
    }
  }, [values?.unitType]);

  useEffect(() => {
    let cost = 0;
    let Totalcost;

    partsArray.forEach((item, index) => {
      cost += parseFloat(item.orderedQty) * item.cost;
    });

    let LC = values['labourCost'] ? parseFloat(values['labourCost']) : 0;
    Totalcost = cost + LC;
    handleEdit(cost, { field: 'partCost' });
    handleEdit(Totalcost.toFixed(2), { field: 'cost' });
  }, [partsArray, values['labourCost']]);

  useEffect(() => {
    if (values?.partsArray?.length > 0) {
      let a = [];
      values.partsArray.forEach((e) => {
        let tempItem = Object.assign({}, e);
        tempItem.isDisabled = tempItem.deleted || false;
        tempItem.label = tempItem.partName;
        tempItem.value = tempItem.id;
        a.push(tempItem);
      });

      setPartsArray([...a]);
    }
  }, [values.partsArray]);
  const labourCostChange = (event) => { };

  const viewReportWo = () => {
    if (values.reportId)
      viewReport({
        apiId: 'viewReportApi',
        data: values.reportId,
        open: 'newWindow'
      });
  };

  const viewPicture = () => {
    if (values?.pictureId?.length > 0) {
      getDocumentToken({
        apiId: 'viewApi',
        callback: viewPictureCallback
      });
    }
  };
  const [open, setOpen] = useState(false);
  const [ModalObject, setModalObject] = useState({
    header: '',
    content: () => {
      return null;
    }
  });
  const viewPictureCallback = (type, data) => {
    if (values?.pictureId?.length > 0) {
      setModalObject({
        header: '',
        content: () => {
          return (
            <Carousel
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
            >
              {values?.pictureId?.map((photo) => {
                return (
                  <div style={{ minHeight: "100px" }}>
                    <img
                      src={`${beBaseUrl}/document/open/${photo.files[0].id}?token=${data}`}
                    />
                    <p className="legend">
                      {photo?.isAdv ? `${photo?.categoryType}.${photo?.sectionName}: ${photo?.info}` : `${photo?.sectionName}${photo?.info ? ` :${photo?.info}` : ""}`}
                    </p>
                  </div>
                );
              })}
            </Carousel>
          );
        }
      });
      setOpen(true);
    }
  };
  const closeModal = (event) => {
    setOpen(false);
  };
  return (
    <Styled.MainWrapper>
      {values['isEdit'] && (
        <div className="workOrderIdWrapper">
          <span>{!isDVIROnlyFlagEnabled ? i18n.t('workOrders.workOrderNo') : i18n.t('workOrders.workOrderNoDvir')} : {values.idPub}</span>
        </div>
      )}
      <Styled.Wrapper className="addworkorder">
        <Styled.LeftWrapper>
          <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
            <SubHeader
              className="subheaderSection statusHeader userHeading"
              text={i18n.t('workOrders.status')}
              variant="h2"
            />
            <div className="statusSection">
              {statusArray.map((items, index) => {
                return (
                  <ul>
                    <li onClick={() => handleClickStatus(items.value, index)} className={((editData?.serviceCenterId && hasRestrictedRole(['FLMANAGER']) && isDVIROnlyFlagEnabled) || customProps?.viewOnly) ? 'status-disabled' : ''}>
                      <img
                        src={items.selected ? items.selectedImg : items.img}
                        alt="close"
                      />
                      {items.selected &&
                        editData?.state === 'APPROVED' &&
                        items.value === 'COMPLETED' &&
                        (<span>Approved</span>)}
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>

          {!values['isEdit'] && (
            <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
              <SubHeader
                className="subheaderSection userHeading"
                text={i18n.t('workOrders.inspectionDetails')}
                variant="h2"
              />
              <FormStyle.default.FieldsWrapper className="paddingLeft">
                <Label htmlFor="unitType" variant="body1">
                  {i18n.t('workOrders.unitType')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="unitType"
                    id="unitType"
                    onChange={(event) => {
                      handleEdit(event, { field: 'unitType' });
                      handleEdit('', { field: 'unitId' });
                      handleEdit('', { field: 'vin' });
                      {
                        /* customProps.setInitialValues({
                    ...values,
                    unitType: event
                  }); */
                      }
                    }}
                    as={Select}
                    placeholder={i18n.t('common.pleaseSelect')}
                    value={getFilterLocaleValues([values['unitType']], i18n)}
                    suggestions={getFilterLocaleValues(unitType, i18n)}
                  />
                  {showError(
                    'unitType',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>

              {values?.unitType?.value && (
                <FormStyle.default.FieldsWrapper className="paddingLeft">
                  <Label htmlFor="unitId" variant="body1">
                    {i18n.t('vehicles.unitId')}
                  </Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="unitId"
                      onChange={(event) => {
                        handleEdit(event, { field: 'unitId' });
                        {
                          /* customProps.setInitialValues({
                    ...values,
                    unitId: event
                  }); */
                        }
                        handleEdit(event.vin, { field: 'vin' });
                      }}
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={values['unitId']}
                      suggestions={
                        values?.unitType?.value === 'VEHICLE'
                          ? vehicleList
                          : trailerList
                      }
                    />
                    {showError(
                      'unitId',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              )}
              <FormStyle.default.FieldsWrapper className="paddingLeft">
                <Label htmlFor="type" variant="body1">
                  {i18n.t('common.type')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="type"
                    onChange={(event) => {
                      handleEdit(event, { field: 'type' });
                      {
                        /* customProps.setInitialValues({
                    ...values,
                    type: event
                  }); */
                      }
                    }}
                    as={Select}
                    placeholder={i18n.t('common.pleaseSelect')}
                    value={getFilterLocaleValues([values['type']], i18n)}
                    suggestions={getFilterLocaleValues(typeRepair, i18n)}
                  />
                  {showError(
                    'type',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>

              <FormStyle.default.FieldsWrapper className="paddingLeft">
                <Label variant="body1">{i18n.t('vehicles.vin')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="vin"
                    disabled={true}
                    as={Input}
                    value={values['vin']}
                    type="text"
                  />
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>

              {values?.unitType?.value === 'VEHICLE' && (
                <FormStyle.default.FieldsWrapper className="paddingLeft">
                  <Label variant="body1">
                    {i18n.t('refillsHistory.odometer')}
                  </Label>
                  <FormStyle.default.TextareaWrapper>
                    <Field
                      name="mileage"
                      handleOnChange={(event) => {
                        {
                          /* customProps.setInitialValues({
                    ...values,
                    mileage: event
                  }); */
                        }

                        handleEdit(event, { field: 'mileage' });
                      }}
                      as={Input}
                      value={values['mileage']}
                      type="text"
                    />

                    {showError(
                      'mileage',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextareaWrapper>
                </FormStyle.default.FieldsWrapper>
              )}
            </div>
          )}

          {values['isEdit'] && (
            <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
              <SubHeader
                className="subheaderSection userHeading woNew"
                text={i18n.t('workOrders.inspectionDetails')}
                variant="h2"
              />
              <div className="editInspection">
                <div className="detailSection">
                  <SubHeader
                    className="subheaderSection userHeading woOld"
                    text={i18n.t('workOrders.inspectionDetails')}
                    variant="h2"
                  />
                  <div className="addedValues">
                    <Label className="labels">
                      {i18n.t('vehicles.unitId')}
                    </Label>
                    <Label className="values">{editData?.unitId}</Label>
                  </div>

                  <div className="addedValues">
                    <Label className="labels">{i18n.t('common.type')}</Label>
                    <Label className="values">{editData?.type}</Label>
                  </div>

                  <div className="addedValues">
                    <Label className="labels">{i18n.t('common.driver')}</Label>
                    <Label className="values">{editData?.driverName}</Label>
                  </div>

                  <div className="addedValues">
                    <Label className="labels">{i18n.t('vehicles.vin')}</Label>
                    <Label className="values">{editData?.vin}</Label>
                  </div>

                  <div className="addedValues">
                    <Label className="labels">
                      {i18n.t('refillsHistory.odometer')}
                    </Label>
                    <Label className="values">{editData?.mileage}</Label>
                  </div>

                  <div className="addedValues">
                    <Label className="labels">
                      {i18n.t('common.reported')}
                    </Label>
                    <Label className="values">{editData?.reportedDate}</Label>
                  </div>
                  {((editData?.serviceCenterId && hasRestrictedRole(['FLMANAGER']) && isDVIROnlyFlagEnabled) || customProps?.viewOnly) && (
                    <div className="addedValues">
                      <Label className="labels">
                        {i18n.t('workOrders.concern')}
                      </Label>
                      <div style={{ minWidth: "unset" }} className="values">{editData?.concern ? editData.concern : i18n.t('workOrders.unavailable')}</div>
                    </div>
                  )}
                  {customProps?.viewOnly && (
                    <>
                      <div className="addedValues">
                        <Label className="labels">
                          {i18n.t('workOrders.cause')}
                        </Label>
                        <pre className="values">
                          {editData?.cause
                            ? editData.cause
                            : i18n.t('workOrders.unavailable')}
                        </pre>
                      </div>
                      <div className="addedValues">
                        <Label className="labels">
                          {i18n.t('workOrders.correction')}
                        </Label>
                        <pre className="values">
                          {editData?.correction
                            ? editData.correction
                            : i18n.t('workOrders.unavailable')}
                        </pre>
                      </div>
                    </>
                  )}
                </div>
                <div className="inspectionView">
                  <SubHeader
                    className="subheaderSection userHeading woOld"
                    text={i18n.t('workOrders.inspectionReport')}
                    variant="h2"
                  />
                  {values?.reportId && <Icon
                    src={getImagePath(imageUrls.viewReport)}
                    tooltip={i18n.t('workOrders.viewReport')}
                    className="woNew "
                    onClick={() => viewReportWo()}
                  />}
                  {
                    values?.pictureId?.length > 0 &&
                    (<Icon
                      src={getImagePath(imageUrls.viewPicture)}
                      tooltip={i18n.t('workOrders.viewPicture')}
                      className="woNew"
                      onClick={() => viewPicture()}
                    />)
                  }
                  <Button
                    className="woInspection viewworeport woOld"
                    label={i18n.t('workOrders.viewInspectionreport')}
                    onClick={(e) => {
                      viewReportWo();
                    }}
                  />
                  <Button
                    className="woInspection woOld"
                    label={i18n.t('workOrders.viewPhotos')}
                    onClick={() => viewPicture()}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
            {(!(editData?.serviceCenterId && hasRestrictedRole(['FLMANAGER']) && isDVIROnlyFlagEnabled) && !customProps?.viewOnly) &&
              (<FormStyle.default.FieldsWrapper className="paddingLeft noteSection">
                <Label variant="body1" htmlFor="concern">
                  {i18n.t('workOrders.concern')}
                </Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="concern"
                    id="concern"
                    handleOnChange={(event) =>
                      handleEdit(event, { field: 'concern' })
                    }
                    multiLine
                    value={values?.concern}
                    as={Input}
                  />
                  {showError(
                    'concern',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>)}
            {!isDVIROnlyFlagEnabled && !customProps?.viewOnly &&
              (<>
                <FormStyle.default.FieldsWrapper className="paddingLeft noteSection">
                  <Label variant="body1" htmlFor="cause">
                    {i18n.t('workOrders.cause')}
                  </Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="cause"
                      id="cause"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'cause' })
                      }
                      multiLine
                      value={values?.cause}
                      as={Input}
                    />
                    {showError(
                      'cause',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>

                <FormStyle.default.FieldsWrapper className="paddingLeft noteSection">
                  <Label variant="body1" htmlFor="correction">
                    {i18n.t('workOrders.correction')}
                  </Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="correction"
                      id="correction"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'correction' })
                      }
                      multiLine
                      value={values?.correction}
                      as={Input}
                    />
                    {showError(
                      'correction',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              </>)}
          </div>

          {workOrders?.getData?.enableParts &&
            !isDVIROnlyFlagEnabled &&
            (window.$environment.CURRENT_LAYOUT === 'NewUi' || window.$environment.SERVICE_PROVIDER === 'clutch' || window.$environment.SERVICE_PROVIDER === 'spireon') && (
              <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>

                <div className="Forgs partSpecific">
                  <div className="partsWrapper">
                    {(!customProps?.viewOnly || (customProps?.viewOnly && partsArray.length > 0)) &&
                      (<SubHeader
                        className="subheaderSection userHeading"
                        text={i18n.t('workOrders.parts')}
                        variant="h2"
                      />)}
                    {!customProps?.viewOnly &&
                      (<span
                        className="addPartsPlus"
                        onClick={() => addPartsClicked()}
                      >
                        +
                      </span>)}
                  </div>
                  {partsArray.map((item, index) => {
                    return (
                      <>
                        <FormStyle.default.FieldsWrapper className="addPartform">
                          <FormStyle.default.TextWrapper className="addPartSelect">
                            <Field
                              name="part"
                              onChange={(event) => {
                                handlePartsChange(item, event);
                              }}
                              as={Select}
                              placeholder={i18n.t('common.pleaseSelect')}
                              value={getFilterLocaleValues([item], i18n)}
                              suggestions={getFilterLocaleValues(toggleParts || [], i18n)}
                              disabled={customProps?.viewOnly}
                            />
                          </FormStyle.default.TextWrapper>

                          <FormStyle.default.TextWrapper className="addPartfield">
                            <Field
                              name="email"
                              handleOnChange={(event) => {
                                handleCountChange(item, event);
                              }}
                              as={Input}
                              value={item.orderedQty}
                              type="text"
                              disabled={customProps?.viewOnly}
                            />
                          </FormStyle.default.TextWrapper>
                          {!customProps?.viewOnly &&
                            (<span
                              onClick={() => removePartsClicked(item, index)}
                              style={{ color: 'white' }}
                              className="deleteParts"
                            ></span>)}
                        </FormStyle.default.FieldsWrapper>
                      </>
                    );
                  })}
                </div>
              </div>
            )}
        </Styled.LeftWrapper>

        <Styled.RightWrapper>
          <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
            <SubHeader
              className="subheaderSection userHeading"
              text={isDVIROnlyFlagEnabled ? i18n.t('workOrders.addHeadingDvir') : i18n.t('workOrders.addHeading')}
              variant="h2"
            />
            {(!(editData?.serviceCenterId && hasRestrictedRole(['FLMANAGER']) && isDVIROnlyFlagEnabled) && !customProps?.viewOnly) ? (
              <div className="detailSection">
                {!isDVIROnlyFlagEnabled &&
                  (<>
                    <FormStyle.default.FieldsWrapper className="fieldWrapper">
                      <Label variant="body1" htmlFor="labourCost">
                        {i18n.t('workOrders.laborCost')}
                      </Label>
                      <FormStyle.default.TextWrapper>
                        <Field
                          name="labourCost"
                          handleOnChange={(event) => {
                            handleEdit(event, { field: 'labourCost' });
                            {
                              /* customProps.setInitialValues({
                              ...values,
                              labourCost: event
                            }); */
                            }
                            {
                              /* labourCostChange(event) */
                            }
                          }}
                          as={Input}
                          placeholderText={i18n.t('common.labourCost')}
                          value={values['labourCost']}
                          type="text"
                        />
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper>
                    <FormStyle.default.FieldsWrapper className="fieldWrapper">
                      <Label variant="body1" htmlFor="partCost">
                        {i18n.t('workOrders.partsCost')}
                      </Label>
                      <FormStyle.default.TextWrapper>
                        <Field
                          name="partCost"
                          as={Input}
                          placeholderText={i18n.t('common.partCost')}
                          value={parseFloat(values['partCost']).toFixed(2)}
                          type="text"
                          disabled
                        />
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper>
                    <FormStyle.default.FieldsWrapper className="fieldWrapper">
                      <Label variant="body1" htmlFor="cost">
                        {i18n.t('workOrders.totalCost')}
                      </Label>
                      <FormStyle.default.TextWrapper>
                        <Field
                          name="cost"
                          as={Input}
                          placeholderText={i18n.t('common.cost')}
                          value={parseFloat(values['cost']).toFixed(2)}
                          type="text"
                          disabled
                        />
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper>
                  </>)}
                {!isSingleRoleOnlyUser('MECHANIC') && (
                  <FormStyle.default.FieldsWrapper className="fieldWrapper Forgs">
                    <Label variant="body1" htmlFor="mechanicId">
                      {i18n.t('workOrders.mechanic')}
                    </Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="mechanicId"
                        onChange={(event) => {
                          // setNewValues('licenseState', event)
                          handleEdit(event, { field: 'mechanicId' });
                          {
                            /* customProps.setInitialValues({
                            ...values,
                            mechanicId: event
                          }); */
                          }
                        }}
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values['mechanicId']}
                        suggestions={vehicleMechanicList || []}
                        id={'mechanic_slect_wo_gs'}
                      />
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>)
                }
                <FormStyle.default.FieldsWrapper className="fieldWrapper">
                  <Label variant="body1" htmlFor="beginDate">
                    {i18n.t('workOrders.scheduled')}
                  </Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="beginDate"
                      onChange={(event) => {
                        handleEdit(event, { field: 'beginDate', type: 'date' });
                        // setNewValues('beginDate', event)
                        {
                          /* customProps.setInitialValues({
                          ...values,
                          beginDate: moment(event).format(UI_DATE_FORMAT)
                        }); */
                        }
                      }}
                      locale={getDateLocale()}
                      as={ReactDatePicker}
                      placeholderText={UI_DATE_LOWER_FORMAT}
                      value={values['beginDate']}
                      type="date"
                      showPopperArrow={false}
                      showTimeSelect={false}
                      showDisabledMonthNavigation
                      showMonthDropdown={values['isEdit'] ? true : false}
                      showYearDropdown={values['isEdit'] ? true : false}
                      dropdownMode="select"
                      minDate={new Date()}
                      maxDate={
                        values.endDate ? new Date(values.endDate) : '01/01/3000'
                      }
                    />
                    {showError(
                      'beginDate',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
                <FormStyle.default.FieldsWrapper className="fieldWrapper">
                  <Label variant="body1" htmlFor="endDate">
                    {i18n.t('workOrders.dueDate')}
                  </Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="endDate"
                      onChange={(event) => {
                        handleEdit(event, { field: 'endDate', type: 'date' });
                      }}
                      locale={getDateLocale()}
                      as={ReactDatePicker}
                      placeholderText={UI_DATE_LOWER_FORMAT}
                      value={values['endDate']}
                      type="date"
                      showPopperArrow={false}
                      showTimeSelect={false}
                      showDisabledMonthNavigation
                      showMonthDropdown={values['isEdit'] ? true : false}
                      showYearDropdown={values['isEdit'] ? true : false}
                      dropdownMode="select"
                      minDate={
                        moment(new Date(values.beginDate)).unix() > moment(new Date()).unix()
                          ? new Date(values.beginDate)
                          : new Date()
                      }
                    />
                    {showError(
                      'endDate',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
                <FormStyle.default.FieldsWrapper className="fieldWrapper Formechanic">
                  <Label variant="body1" htmlFor="mechanicId">
                    {i18n.t('workOrders.mechanic')}
                  </Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="mechanicId"
                      onChange={(event) => {
                        // setNewValues('licenseState', event)
                        handleEdit(event, { field: 'mechanicId' });
                        {
                          /* customProps.setInitialValues({
                          ...values,
                          mechanicId: event
                        }); */
                        }
                      }}
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={values['mechanicId']}
                      suggestions={vehicleMechanicList || []}
                      id={'mechanic_slect_wo'}
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
                <FormStyle.default.FieldsWrapper className="noteSection">
                  <Label variant="body1" htmlFor="notes">
                    {i18n.t('workOrders.notes')}
                  </Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="notes"
                      id="notes"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'note' })
                      }
                      multiLine
                      value={values?.note}
                      as={Input}
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              </div>) : (
              <div className="editInspection">
                <div className="detailSection editServiceRequest">
                  {
                    customProps?.viewOnly &&
                    (<>
                      <div className="addedValues">
                        <Label className="labels">
                          {i18n.t('workOrders.laborCost')}
                        </Label>
                        <Label className="values">{editData?.labourCost}</Label>
                      </div>
                      <div className="addedValues">
                        <Label className="labels">
                          {i18n.t('workOrders.partsCost')}
                        </Label>
                        <Label className="values">{editData?.partCost}</Label>
                      </div>
                      <div className="addedValues">
                        <Label className="labels">
                          {i18n.t('workOrders.totalCost')}
                        </Label>
                        <Label className="values">{editData?.cost}</Label>
                      </div>
                    </>)
                  }
                  <div className="addedValues">
                    <Label className="labels">
                      {i18n.t('workOrders.mechanic')}
                    </Label>
                    <Label className="values">{editData?.mechanicName ? editData.mechanicName : i18n.t('workOrders.unavailable')}</Label>
                  </div>
                  <div className="addedValues">
                    <Label className="labels">
                      {i18n.t('workOrders.scheduled')}
                    </Label>
                    <Label className="values">
                      {editData?.beginDate
                        ? moment(editData.beginDate).format(UI_DATE_FORMAT)
                        : i18n.t('workOrders.unavailable')}
                    </Label>
                  </div>

                  <div className="addedValues">
                    <Label className="labels">
                      {i18n.t('workOrders.dueDate')}
                    </Label>
                    <Label className="values">
                      {editData?.endDate
                        ? moment(editData.endDate).format(UI_DATE_FORMAT)
                        : i18n.t('workOrders.unavailable')}
                    </Label>
                  </div>

                  <div className="addedValues">
                    <Label className="labels">{i18n.t('workOrders.notes')}</Label>
                    <pre className="values">{editData?.note ? editData.note : i18n.t('workOrders.unavailable')}</pre>
                  </div>
                </div>
              </div>
            )}
          </div>
          {workOrders?.getData?.enableParts &&
            !isDVIROnlyFlagEnabled &&
            window.$environment.CURRENT_LAYOUT === 'OldUi' && (
              <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
                <div className="Fores partSpecific">
                  <div className="partsWrapper">
                    <SubHeader
                      className="subheaderSection userHeading"
                      text={i18n.t('workOrders.parts')}
                      variant="h2"
                    />
                    <span
                      className="addPartsPlus"
                      onClick={() => addPartsClicked()}
                    >
                      +
                    </span>
                  </div>
                  {partsArray.map((item, index) => {
                    return (
                      <>
                        <FormStyle.default.FieldsWrapper className="addPartform">
                          <FormStyle.default.TextWrapper className="addPartSelect">
                            <Field
                              name="part"
                              onChange={(event) => {
                                handlePartsChange(item, event);
                              }}
                              as={Select}
                              placeholder={i18n.t('common.pleaseSelect')}
                              value={getFilterLocaleValues([item], i18n)}
                              suggestions={getFilterLocaleValues(toggleParts || [], i18n)}
                            />
                          </FormStyle.default.TextWrapper>

                          <FormStyle.default.TextWrapper className="addPartfield">
                            <Field
                              name="email"
                              handleOnChange={(event) => {
                                handleCountChange(item, event);
                              }}
                              as={Input}
                              value={item.orderedQty}
                              type="text"
                            />
                          </FormStyle.default.TextWrapper>
                          <span
                            onClick={() => removePartsClicked(item, index)}
                            style={{ color: 'white' }}
                            className="deleteParts"
                          ></span>
                        </FormStyle.default.FieldsWrapper>
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          {!isDVIROnlyFlagEnabled &&
            <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
              {(!customProps.viewOnly || (customProps.viewOnly && isDocumentAvailable)) &&
                (<SubHeader
                  text={i18n.t('workOrders.receipts')}
                  className="subheaderSection documentsHeader"
                  variant="h2"
                />)}
              <Styled.documentWrapper>
                <UploadList
                  vehicleIdPub={values['idPub']}
                  isEdit={values['isEdit']}
                  list={uploadList.currentDocs}
                  saveDocument={customProps.saveDocument}
                  deleteDocument={customProps.deleteDocument}
                  apiStatus={customProps.apiStatus}
                  callback={customProps.callback}
                  downloadDocument={customProps.downloadDocument}
                  viewOnly={customProps?.viewOnly}
                />
              </Styled.documentWrapper>
            </div>}
          {hasEnabledFeature('inspectionPdfCanadaEnabled') && (workOrders?.getData?.workOrderEditDto?.state === 'SCHEDULED' || workOrders?.getData?.workOrderEditDto?.state === 'COMPLETED') &&
            <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
              <SubHeader
                className="subheaderSection userHeading"
                text={i18n.t('workOrders.dailyLiquidDetails')}
                variant="h2"
              />
              {(!(editData?.serviceCenterId && hasRestrictedRole(['FLMANAGER']) && isDVIROnlyFlagEnabled) && !customProps?.viewOnly) ?
                <div className="detailSection">
                  <FormStyle.default.FieldsWrapper className="fieldWrapper setWidth">
                    <Label variant="body1" htmlFor="fuel">
                      {i18n.t('workOrders.fuel')}
                    </Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="fuel"
                        id="fuel"
                        handleOnChange={(event) =>
                          handleEdit(event, { field: 'fuel' })
                        }
                        value={values?.fuel}
                        as={Input}
                      />
                      {showError(
                        'fuel',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                  <FormStyle.default.FieldsWrapper className="fieldWrapper setWidth">
                    <Label variant="body1" htmlFor="engineOil">
                      {i18n.t('workOrders.engineOil')}
                    </Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="engineOil"
                        id="engineOil"
                        handleOnChange={(event) =>
                          handleEdit(event, { field: 'engineOil' })
                        }
                        value={values?.engineOil}
                        as={Input}
                      />
                      {showError(
                        'engineOil',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                  <FormStyle.default.FieldsWrapper className="fieldWrapper setWidth">
                    <Label variant="body1" htmlFor="hydraulicOil">
                      {i18n.t('workOrders.hydraulicOil')}
                    </Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="hydraulicOil"
                        id="hydraulicOil"
                        handleOnChange={(event) =>
                          handleEdit(event, { field: 'hydraulicOil' })
                        }
                        value={values?.hydraulicOil}
                        as={Input}
                      />
                      {showError(
                        'hydraulicOil',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                  <FormStyle.default.FieldsWrapper className="fieldWrapper setWidth">
                    <Label variant="body1" htmlFor="windShieldFluid">
                      {i18n.t('workOrders.windShieldFluid')}
                    </Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="windShieldFluid"
                        id="windShieldFluid"
                        handleOnChange={(event) =>
                          handleEdit(event, { field: 'windShieldFluid' })
                        }
                        value={values?.windShieldFluid}
                        as={Input}
                      />
                      {showError(
                        'windShieldFluid',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                </div>
                :
                <>
                  <div className="editInspection">
                    <div className="detailSection editServiceRequest">
                      <div className="addedValues">
                        <Label className="labels">
                          {i18n.t('workOrders.fuel')}
                        </Label>
                        <Label className="values">{values?.fuel ? values.fuel : i18n.t('workOrders.unavailable')}</Label>
                      </div>
                      <div className="addedValues">
                        <Label className="labels">
                          {i18n.t('workOrders.engineOil')}
                        </Label>
                        <Label className="values">{values?.engineOil ? values.engineOil : i18n.t('workOrders.unavailable')}</Label>
                      </div>
                      <div className="addedValues">
                        <Label className="labels">
                          {i18n.t('workOrders.hydraulicOil')}
                        </Label>
                        <Label className="values">{values?.hydraulicOil ? values.hydraulicOil : i18n.t('workOrders.unavailable')}</Label>
                      </div>
                      <div className="addedValues">
                        <Label className="labels">
                          {i18n.t('workOrders.windShieldFluid')}
                        </Label>
                        <Label className="values">{values?.windShieldFluid ? values.windShieldFluid : i18n.t('workOrders.unavailable')}</Label>
                      </div>
                    </div>
                  </div>
                </>}
            </div>}
        </Styled.RightWrapper>
      </Styled.Wrapper>
      {typeof customProps.hasApiStatus?.errorMessage === 'string' && (
        <Message
          type="error"
          asTags
          message={i18n.t(customProps.hasApiStatus.errorMessage)}
        />
      )}
      {isDVIROnlyFlagEnabled && (<DefectList
        defects={workOrders?.getData?.workOrderEditDto?.defects || []}
        getDocumentToken={getDocumentToken}
        setOpen={setOpenPhoto}
        setModalObject={setModalObjectPhoto}
      />)}
      {(!(editData?.serviceCenterId && hasRestrictedRole(['FLMANAGER']) && isDVIROnlyFlagEnabled) && !customProps?.viewOnly) &&
        (<Styled.submitUser>
          <FormStyle.default.ButtonsWrapper className="submitSection">
            <Button
              className="buttonStyle"
              type="reset"
              label={i18n.t('common.cancel')}
              onClick={(e) => {
                cancelAction();
              }}
            />
            <Button
              type="submit"
              className="buttonStyle"
              label={i18n.t('common.save')}
              onClick={(e, d) => {
                const validateResponse = validateForm();
                validateResponse.then((data) => {
                  submitAction(data);
                });
              }}
            />
          </FormStyle.default.ButtonsWrapper>
        </Styled.submitUser>)}
      <Modal
        open={open}
        setOpen={setOpen}
        data={ModalObject}
        onCloseModal={closeModal}
        background="black"
        withCloseIcon
      />
    </Styled.MainWrapper>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
