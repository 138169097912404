import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import React, { lazy } from 'react';
import InfoStyle from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const SvgIcon = lazy(() => import(`@/components/SvgIcon/${layout}`));

const InfoCard = ({
    fleetIconMapping,
    companyName = '',
    totalCount = '0',
}) => {
    return (
        <Tooltip mouseEnterDelay={0.5} placement='top' overlayStyle={{ maxWidth: "300px", opacity: 1 }} overlay={<span>{'NA' || 'NA'}</span>}>
        <InfoStyle>
            <Label className="text">
                {companyName}
            </Label>
            <div class="content">
            <Label className="count">
                {totalCount}
            </Label>
            <div>
                <Label className="label">
                    Total
                </Label>
                <Label className="label">
                    Vehicles
                </Label>
            </div>
           <SvgIcon
                value={
                    fleetIconMapping['infoTruck']
                    ? fleetIconMapping['infoTruck']
                    : 'bobTail'
                }
            />
          </div>
        </InfoStyle>
      </Tooltip>
    );
}

export default InfoCard;
