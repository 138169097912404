import styled from "styled-components";


const Wrapper = styled.div`
    ${({ them , index }) => `
        position: relative;
        .labelWrapper {
            position: absolute;
            top: 0;
            // left: 50%;
            width: 100%;
            height: 100%;
            // transform: translate(-50%, -50%);
            textAlign: center;
        }
        .label {
                font-weight: 800;
                text-align: center;
                font-size: 32px;
        }
        .gauge-wrapper {
            position: relative;
            padding: 10px;
            background: #000;
            border-radius: 20px;
        }
        .gauge .tick:first-child,
        .gauge .tick:last-child {
            display: none;
        }
    `}
`;

const ItemLabel = styled.div`
    ${({ key }) => `
        transform: rotate(${(key * 90) - 45}deg);
        transformOrigin: left center;
        width: fit-content;
        position: absolute;
        .position1 {
            position: absolute;
            white-space: nowrap;
            bottom: -100px;
        }
            .position2 {
            position: absolute;
            white-space: nowrap;
            left: 52px;
        }
            .position3 {
            position: absolute;
            white-space: nowrap;
                left: 218px;
        }
            .position4 {
            position: absolute;
            white-space: nowrap;
                left: 277px;
                            bottom: -100px;
        }
    `}
`;


const Styled = {
    Wrapper,
    ItemLabel,
}
export default Styled;