import styled from "styled-components";

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    .centerText {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -65%);
        text-align: center;
    }
    .centerText3 {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -65%);
        text-align: center;
    }
    .text {
        font-size: 14px;
        font-weight: normal;
    }
    .count {
        font-size: 24px;
        font-weight: bold;
        padding: 0px;
        display: ruby-text;
    }
    .textsmall {
        font-size: 8px;
        font-weight: normal;
    }
    .countsmall {
        font-size: 12px;
        font-weight: bold;
        padding: 0px;
        display: ruby-text;
    }
    .centerText2 {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -65%);
        text-align: center;
        .text {
            font-size: 8px;
            font-weight: normal;
        }
        .count {
            font-size: 12px;
            font-weight: bold;
            padding: 0px;
            display: ruby-text;
        }
    }
    
`;

const Styled = {
    Wrapper
};

export default Styled;