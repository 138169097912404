import React, { lazy, useState } from 'react';
import { useTranslation } from 'react-i18next';


const layout = window?.$environment?.CURRENT_LAYOUT;
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));

const defaultFilter = [
    {
        label: 'Last 30 days',
        value: 'MONTHLY'
    },
    {
        label: 'Current Policy time Period',
        value: 'YEARLY'
    }
];

const MainFilter = ({
    onChange = () => { },
    items = defaultFilter,
}) => {
    const [value, setValue] = useState(defaultFilter[0])
    const i18n = useTranslation();
    return (
        <FormStyle.default.FieldsWrapper className="horizontal">
            <Label variant="body1" htmlFor="severityId">
                {i18n.t('newScoreCard.selectPeriod')}
            </Label>
            <FormStyle.default.TextWrapper>
                <Select
                    id="severityId"
                    className="choose-period"
                    value={value}
                    onChange={(event) => {
                        setValue(event);
                        onChange(event)
                    }}
                    placeholder={i18n.t('common.pleaseSelect')}
                    suggestions={items || []}
                />
            </FormStyle.default.TextWrapper>
        </FormStyle.default.FieldsWrapper>
    );
}

export default MainFilter;
