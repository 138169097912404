import React, { lazy } from 'react';
import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Label = lazy(() => import(`@/components/UI/Label/${layout}`));

const isUnPoweredViolationList = [
    {
        label: '0% - 9%',
        color: 'green'
    },
    {
        label: '10% - 19%',
        color: 'yellow'
    },
    {
        label: '>=20%',
        color: 'red'
    },
];

const accidentItem = [
    {
        label: '<1%',
        color: 'green'
    },
    {
        label: '1% - 3%',
        color: 'yellow'
    },
    {
        label: '3% - 5%',
        color: 'orange'
    },
    {
        label: '>5%',
        color: 'red'
    },
];

const speedingItem = [
    {
        label: '<5%',
        color: 'green'
    },
    {
        label: '5% - 19%',
        color: 'yellow'
    },
    {
        label: '>=20%',
        color: 'red'
    },
];

const fleetItem = [
    {
        label: 'Excellent',
        color: 'green'
    },
    {
        label: 'Good',
        color: 'yellow'
    },
    {
        label: 'Average',
        color: 'orange'
    },
    {
        label: 'Below Average',
        color: 'red'
    },
];

const driverBehaviourItems = [
    {
        label: 'Distracted Driving',
        color: '#eaeaea'
    },
    {
        label: 'Speeding',
        color: '#cecece'
    },
    {
        label: 'Accident',
        color: '#0097e6'
    },
]

const RangeInfo = ({
    items = speedingItem,
    fleetScore = false,
    isAccident = false,
    smallGraph= false,
    isDriver = false,
    isUnPoweredViolation = false
}) => {
    let listItem = isUnPoweredViolation ? isUnPoweredViolationList : fleetScore ? fleetItem : isAccident? accidentItem : isDriver ? driverBehaviourItems : speedingItem;

    return (
        <Styled.ListItem small={smallGraph}>
            {listItem?.map(e => (<Styled.Item small={smallGraph} color={e?.color}><span></span><Label className="noWrap" variant="body1">
                {e?.label}
            </Label></Styled.Item>))}
        </Styled.ListItem>
    );
};

export default RangeInfo;