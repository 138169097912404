import styled from "styled-components";


const ListItem = styled.ul`
${({ theme, color, small }) => `
    list-style:none;
    padding: 0;
    margin: 0;
    .noWrap {
        white-space: nowrap;
    }
`}
`;

const Item = styled.li`
${({ theme, color, small }) => `
    display:flex;
    align-items: center;
    clear:left; 
    vertical-align:middle;  
    font-size: 14px;
    line-height: 1.5rem; 
    padding-left: 5px;
    margin-bottom: ${small ? '2px' : '1rem'};
    span {
        width: ${small ? '10px' : '18px'}
        height: ${small ? '10px' : '18px'};
        background: ${color || 'red'} !important; 
        display:inline-block;
        margin-right:6px
    }
`}
`;

const Label = styled.p``;
  

const Styled = {
    ListItem,
    Item,
    Label
};

export default Styled;