import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, { lazy } from 'react';
import ToolTip from '../ToolTip';
import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const PercentageBarGraph = lazy(() =>
  import(`@/components/UI/Graph/PercentageBarGraph/${layout}`)
);
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));

const graphGenerator = ({ title, value, total, percentage, info ="infoBox" }) => {
    return (
    <Styled.Items>
        <Styled.Title>
            <Label variant="body1" className="title">{title}
            <ToolTip title={info} arrow placement='top'>
                <InfoOutlinedIcon /></ToolTip></Label>
            <Label variant="body1" className="title">{`${value}/${total}`}</Label>
        </Styled.Title>
        <PercentageBarGraph isFlexed percentage={`${percentage}%`} smallSize />
    </Styled.Items>
    )
}

const FleetScore = ({scoreCard}) => {
    return (
        <Styled.Wrapper>
            {graphGenerator({
                title: 'Driver Behavior',
                value: scoreCard?.aggregatedDrivingBehaviorScore,
                total: '60',
                percentage: scoreCard?.aggregatedDrivingBehaviorScore * 1.665,
                info: `
               Calculated based on the number of driving events. The score represents safe driving behavior, with fewer events leading to a higher score.
Method: 0.6 * Driver Behavior Score
                `
            })}
            {graphGenerator({
                title: 'Unpowered Days',
                value: scoreCard?.aggregatedUnpoweredDaysScore,
                total: '20',
                percentage: scoreCard?.aggregatedUnpoweredDaysScore * 5,
                info: `
               Based on the number of days trucks were unpowered. Fewer unpowered days result in a higher score.
Method: 0.2 * Unpowered Days Score
                `
            })}
            {graphGenerator({
                title: 'Radius Violations',
                value: scoreCard?.aggregatedRadiusViolationsScore,
                total: '20',
                percentage: scoreCard?.aggregatedRadiusViolationsScore * 5,
                info: `
                Measures the days trucks violated the predefined radius. Fewer violation days result in a higher score.
Method: 0.2 * Radius Violation Score
                `
            })}
        </Styled.Wrapper>
    );
}

export default FleetScore;
