import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'yup';

import { connect } from 'react-redux';
import Department from './index';

import { COOKIE_AUTH_DETAILS } from '@/config/constants/keys';
import {
  generateDropdownOptions,
  loadStyle
} from '@/utils/utils';
import { getCookie } from '../../../services/cookie';
import ParentStyled from './style';
let Styled = loadStyle(ParentStyled, 'components/modules/Department');
const layout = window?.$environment?.CURRENT_LAYOUT;
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const Modal = require(`../../UI/Modal/${layout}`).default;
const ErrorIcon = lazy(() => import(`../../ErrorIcon/${layout}`));
const SuccessIcon = lazy(() => import(`../../SuccessIcon/${layout}`));
const token = getCookie(COOKIE_AUTH_DETAILS);

const Wrapper = ({
  regions,
  fetchTerminals,
  fetchDepartments,
  fetchSubDepartments,
  action,
  fetchFilters,
  // fetchAccessLevel,
  // accessLevel,
  fetchRegions,
  saveFilters,
  callback,
  filters,
  fetchCountData,
  setFilterPopupOpen,
}) => {
  const { i18n } = useTranslation();
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => ''
  });
  const [openCallback, setOpenCallback] = useState(false);
  const [disabledCriteria, setDisabledCriteria] = useState({
    regionId: false,
    terminalId: false,
    departmentId: false,
    subDepartmentId: false
  });
  const [region, setRegion] = useState('');
  const [terminal, setTerminal] = useState('');
  const [department, setDepartment] = useState('');
  const [subDepartment, setSubDepartment] = useState('');
  useEffect(() => {
    // fetchAccessLevel({ apiId: 'fetchAccessLevel' });
    fetchFilters({ apiId: 'fetchFilters' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (filters?.companyId !== -1 && filters?.regionId) {
      setRegion(
        filters?.regionId
          ? { label: filters?.regionName, value: filters?.regionId }
          : ''
      );
      setTerminal(
        filters?.terminalId
          ? { label: filters?.terminalName, value: filters?.terminalId }
          : ''
      );
      setDepartment(
        filters?.departmentId
          ? { label: filters?.departmentName, value: filters?.departmentId }
          : ''
      );
      setSubDepartment(
        filters?.subDepartmentId
          ? {
              label: filters?.subDepartmentName,
              value: filters?.subDepartmentId
            }
          : ''
      );
      if ((!filters?.companyId && filters?.regionId)) {
        fetchRegions({ apiId: 'fetchRegions' });
      }
    } else if (filters?.companyId !== -1 || (!filters?.companyId && filters?.regionId)) {
      fetchRegions({ apiId: 'fetchRegions' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  useEffect(() => {
    let object = { ...disabledCriteria };
    if (token?.accessLevel < 1) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = true;
      object.subDepartmentId = true;
    } else if (token?.accessLevel < 2) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = true;
      object.subDepartmentId = false;
      department.value &&
        fetchSubDepartments({
          apiId: 'fetchDSubDepartments',
          data: { id: department.value }
        });
    } else if (token?.accessLevel< 3) {
      object.regionId = true;
      object.terminalId = true;
      object.departmentId = false;
      object.subDepartmentId = false;
      terminal.value &&
        fetchDepartments({
          apiId: 'fetchDepartments',
          data: { id: terminal.value }
        });
    } else if (token?.accessLevel < 4) {
      object.regionId = true;
      object.terminalId = false;
      object.departmentId = false;
      object.subDepartmentId = false;
      region.value &&
        fetchTerminals({
          apiId: 'fetchTerminal',
          data: { id: region.value }
        });
      terminal.value &&
        fetchDepartments({
          apiId: 'fetchDepartments',
          data: { id: terminal.value }
        });
    } else if (token?.accessLevel === 4) {
      region.value &&
        fetchTerminals({
          apiId: 'fetchTerminal',
          data: { id: region.value }
        });
      terminal.value &&
        fetchDepartments({
          apiId: 'fetchDepartments',
          data: { id: terminal.value }
        });
      department.value &&
        fetchSubDepartments({
          apiId: 'fetchDSubDepartments',
          data: { id: department.value }
        });
    } else {
      object.regionId = false;
      object.terminalId = false;
      object.departmentId = false;
      object.subDepartmentId = false;
      fetchRegions({ apiId: 'fetchRegions' });
    }
    setDisabledCriteria(object);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, token]);
  const saveFiltersApi = 'saveFilters';
  return (
    <>
      <Form
        type="server"
        direction="column"
        initialValues={{
          regionId: region,
          terminalId: terminal,
          departmentId: department,
          subDepartmentId: subDepartment
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          const result = {
            regionId: fields?.regionId?.value || null,
            terminalId: fields?.terminalId?.value || null,
            departmentId: fields?.departmentId?.value || null,
            subDepartmentId: fields?.subDepartmentId?.value || null
          };
          saveFilters({
            apiId: saveFiltersApi,
            data: { data: { ...result }, isReset: false },
            callback: (type, message) => {
              setModalListObject({
                header: '',
                content: () => (
                  <div className="successpopup">
                    {type === 'error' ? <ErrorIcon /> : <SuccessIcon />}
                    <span className="warningPopupMessage">
                      {i18n.t(message)}
                    </span>
                    <div className="deletePopupBtn">
                      <Button
                        type="submit"
                        label={i18n.t('common.ok')}
                        onClick={() => {
                          if (type === 'success' && layout === 'Clutch') {
                            fetchCountData({
                              apiId: 'fetchCountData'
                            });
                          }
                          action();
                          fetchFilters({ apiId: 'fetchFilters' });
                          window.location.reload();
                        }}
                      />
                    </div>
                  </div>
                )
              });
              setOpenCallback(true);
            }
          });
        }}
        handleOnCancel={(fields) => {
          const result = {
            regionId: disabledCriteria.regionId ? region.value : null,
            terminalId: disabledCriteria.terminalId ? terminal.value : null,
            departmentId: disabledCriteria.departmentId
              ? department.value
              : null,
            subDepartmentId: disabledCriteria.subDepartmentId
              ? subDepartment.value
              : null
          };
          saveFilters({
            apiId: saveFiltersApi,
            data: { data: { ...result }, isReset: true },
            callback: (type, message) => {
              setModalListObject({
                header: '',
                content: () => (
                  <div className="successpopup">
                    {type === 'error' ? <ErrorIcon /> : <SuccessIcon />}
                    <span className="warningPopupMessage">
                      {i18n.t(message)}
                    </span>
                    <div className="deletePopupBtn">
                      <Button
                        type="submit"
                        label={i18n.t('common.ok')}
                        onClick={() => {
                          if (type === 'success' && layout === 'Clutch') {
                            fetchCountData({
                              apiId: 'fetchCountData'
                            });
                          }
                          action();
                          fetchFilters({ apiId: 'fetchFilters' });
                          window.location.reload();
                        }}
                      />
                    </div>
                  </div>
                )
              });
              setOpenCallback(true);
            }
          });
        }}
        customProps={{
          Styled,
          fetchTerminals,
          regions: generateDropdownOptions(
            regions.data.regions,
            'regionName',
            'id'
          ),
          fetchDepartments,
          terminals: generateDropdownOptions(
            regions.data.terminals,
            'terminalName',
            'terminalId'
          ),
          fetchSubDepartments,
          departments: generateDropdownOptions(
            regions.data.departments,
            'departmentName',
            'departmentId'
          ),
          subDepartments: generateDropdownOptions(
            regions.data.subDepartments,
            'subDepartmentName',
            'subDepartmentId'
          ),
          i18n: i18n,
          action,
          accessLevel:token?.accessLevel,
          disabledCriteria,
          filters,
        }}
        validationSchema={object().shape({
          regionId: object()
            .nullable()
            .required(i18n.t('This field is required'))
        })}
      />
      <Modal
        open={openCallback}
        setOpen={setOpenCallback}
        data={ModalListObject}
        isSuccess
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    regions: state.regions,
    filters: state.filters,
    // accessLevel: state.accessLevel
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchTerminals: (data) => dispatch.regions.fetchTerminals(data),
    fetchRegions: (data) => dispatch.regions.fetchRegions(data),
    fetchDepartments: (data) => dispatch.regions.fetchDepartments(data),
    fetchSubDepartments: (data) => dispatch.regions.fetchSubDepartments(data),
    fetchFilters: (data) => dispatch.filters.fetchData(data),
    // fetchAccessLevel: (data) => dispatch.accessLevel.fetchData(data),
    saveFilters: (data) => dispatch.filters.saveData(data),
    fetchCountData: (data) => dispatch.dashboard.fetchCountData(data)
  };
};

export default connect(mapState, mapDispatch)(Wrapper);

Wrapper.propTypes = {
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  cancelAction
}) => {
  const { Styled } = customProps;
  return (
    <Styled.Filter>
      {customProps?.hasApiStatus?.errorMessage && (
        <Message
          type="error"
          message={customProps.i18n.t(customProps.hasApiStatus.errorMessage)}
        />
      )}
      <FormStyle.default.TitleWrapper>
        <SubHeader variant="h1" text={customProps.i18n.t('leftMenu.filters')} />
      </FormStyle.default.TitleWrapper>
      <Styled.FilterSubmit>
        <Department
          values={{ ...values }}
          handleEdit={handleEdit}
          i18n={customProps.i18n}
          customProps={customProps}
          errors={errors}
          fetchTerminals={customProps.fetchTerminals}
          fetchDepartments={customProps.fetchDepartments}
          fetchSubDepartments={customProps.fetchSubDepartments}
          regions={customProps.regions}
          terminals={customProps.terminals}
          departments={customProps.departments}
          subDepartments={customProps.subDepartments}
          disabledCriteria={customProps.disabledCriteria}
          isNotValid={isNotValid}
        />
        <div className="submitWrapper">
          <FormStyle.default.ButtonsWrapper className="filtersave">
            <Button
              type="submit"
              label={customProps?.i18n?.t('Save')}
              onClick={() => {
                const validateResponse = validateForm();
                validateResponse.then((data) => {
                  submitAction(data);
                });
              }}
            />
          </FormStyle.default.ButtonsWrapper>
          <FormStyle.default.ButtonsWrapper>
            <Button
              type="reset"
              label={customProps?.i18n?.t('Reset')}
              onClick={() => {
                // if (!customProps.filters.regionId) {
                const validateResponse = validateForm();
                validateResponse.then((data) => {
                  cancelAction(data);
                });
                // }
              }}
            />
          </FormStyle.default.ButtonsWrapper>
        </div>
      </Styled.FilterSubmit>
    </Styled.Filter>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
