import Chart from 'chart.js';
import React, { lazy, useEffect, useRef } from 'react';
import Styled from './style';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Label = lazy(() => import(`@/components/UI/Label/${layout}`));

const defaultBackgroundColor = ['green', 'yellow', 'red'];
const defaultData = [];


const DonutChart = ({
    width,
    height,
    backgroundColor = null,
    borderColor = null,
    data = null,
    centerTextData = null,
    small,
    labels = null,
    onClick = null,
    hideHover = false,
}) => {
    const chartRef = useRef();

    useEffect(() => {
        const myChartRef = chartRef.current.getContext('2d');
        if (myChartRef.chart) {
            myChartRef.chart.destroy();
        }
        new Chart(myChartRef, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: data || defaultData,
                    backgroundColor: borderColor || defaultBackgroundColor,
                    borderColor: borderColor || defaultBackgroundColor,
                    borderWidth: 0,
                    borderRadius: 20,
                }],
                ...(labels ? { labels } : {})
            },
            
            options: {
                onClick: function (e, c) {
                    onClick && onClick(e, c);
                },
                legend: {
                    display: true,
                    labels: {
                        boxWidth: 10
                    },
                    position: 'bottom'
                },
                tooltips: {
                    enabled: hideHover ? false : true,
                    callbacks: {
                        enabled: true,
                        label: function (tooltipItem, data) {
                            return '';
                        },
                        title: function(tooltipItem, data) {
                            const dataset = data?.datasets?.[tooltipItem[0]?.datasetIndex || 0];
                            const value = dataset?.data?.[tooltipItem[0].index];
                            console.log(data, tooltipItem, value)
                            return `Vehicles ${value || 0}`;
                        }
                    },
                },
                responsive: true,
                cutoutPercentage: '75',
                elements: {
                    arc: {
                        borderWidth: 5,
                    }
                },
            }
        });
        return () => {
            if (myChartRef.chart) {
                myChartRef.chart.destroy();
            }
        };
    }, [backgroundColor, data, borderColor, labels]);

    return (
        <Styled.Wrapper>
            <canvas id="donutChart" width={width} height={height} ref={chartRef} />
            {centerTextData && <div className={small ? "centerText3" : "centerText"}>
                <Label className={small ? "textsmall" : "text"}>{centerTextData?.text}</Label>
                <Label className={small ? "countsmall" : "count"}>{centerTextData?.count}</Label>
            </div>}
        </Styled.Wrapper>
    )
}

export default DonutChart;
