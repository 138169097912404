import { documentDeleteApi, documentUploadApi, fetchPendingMechanicInspections } from '@/config/apis';
import { pageUrls } from '@/config/constants/keys';
import { getAuthDetails, hasRestrictedCommmonRole } from '@/utils/utils';

const initialState = {
  list: [],
  filteredList: [],
  getData: {},
  totalCount: 0,
  documentsList: []
};

export const workOrders = {
  state: { ...initialState },
  reducers: {
    listSuccess(state, payload) {
      return {
        ...state,
        list: [...payload],
        filteredList: [...payload]
      };
    },
    clearState(state, payload) {
      return {
        ...state,
        ...initialState
      }
      },
    saveTotalCount(state, payload) {
      return {
        ...state,
        totalCount: payload
      }
    },
    listFailed() {
      return { ...initialState };
    },
    filterSuccess(state, list) {
      return { ...state, filteredList: [...list] };
    },
    listAddSuccess(state, payload) {
      return {
        ...state,
        getData: { ...payload },
        documentsList: [...payload.workOrderEditDto.receipts]
      };
    },
    updateLocalDocumentList(state, response, data) {
      let reqData
      if(data && data.request) 
      reqData = JSON.parse(data?.request)
      let edited = false;
      let woAddObject = {
        id: null,
        userId: null,
        vehicleIdPub: null,
        trailerIdPub: null,
        companyId: null,
        workOrderIdPub: null,
        file: null,
        errorMessage: null,
        editUpload: null,
        type: {
          name: 'WORK_ORDER_IMAGE',
          order: 600,
          fullName: 'Work Order Image',
          documentGroup: 'WORK_ORDER'
        },
        name: null,
        url: null,
        uploaded: null,
        expiration_date: null,
        doctype: 'WORK_ORDER'
      };

      const isWoEmpty = (currentValue) =>
        currentValue?.id === null &&
        currentValue?.type?.name === 'WORK_ORDER_IMAGE';

      const documents = state.documentsList
        ? state.documentsList.map((item) => {
            if (item && Object.keys(item).length > 0) {
              delete item?.filter;
              if (item?.id !== null && item.id === reqData?.id) {
                edited = true;
                return { ...response.response};
              }
              else if (
                item?.type?.name === 'WORK_ORDER_IMAGE' &&
                item?.id === null &&
                !response?.isDelete &&
                item.type.name === response?.response?.type?.name
                && !edited
              ) {
                return { ...response.response };
              } else if (
                item?.type?.name === 'PARTS_INVOICE' &&
                item.type.name === response?.response?.type?.name &&
                !response?.isDelete
              ) {
                return { ...response.response };
              } else if (
                item?.id === response?.id &&
                item?.type?.name === 'WORK_ORDER_IMAGE' &&
                state.documentsList.some(isWoEmpty)
              ) {
                if (response?.isDelete) {
                  return {
                    ...item,
                    id: null,
                    name: null,
                    url: null,
                    uploaded: null,
                    expiration_date: null,
                    filter: 'remove'
                  };
                } else {
                  return { ...item };
                }
              } else if (item?.id === response?.id) {
                if (response?.isDelete) {
                  return {
                    ...item,
                    id: null,
                    name: null,
                    url: null,
                    uploaded: null,
                    expiration_date: null
                  };
                } else {
                  return { ...item };
                }
              } else {
                return { ...item };
              }
            }
          })
        : [{ ...response.response }];

      const isLoaded = (currentValue) => currentValue?.id !== null;
      if (documents.every(isLoaded)) documents.push(woAddObject);

      return {
        ...state,
        documentsList: [
          ...documents.filter(
            (value, index) =>
              Object.keys(value).length > 0 && value?.filter !== 'remove'
          )
        ]
      };
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getWorkOrdersApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: payload.data
              ? {
                  ...apiObject,
                  url: apiObject.url + payload.data
                }
              : apiObject
          },
          id: payload.apiId
        });
        response &&
          response.map((item) => {
            item['driverName'] = item.driver?.name;
            item['mechanicName'] = item.mechanic?.name;
            item['unitId'] = item.unit_id?.name;
          });
        response && this.listSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchPaginateData(payload, rootState) {
      try {
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: fetchPendingMechanicInspections,
            data: hasRestrictedCommmonRole(['MECHANIC'], ['DRIVER']) ? {...payload?.data, mechanicId: getAuthDetails().userId } : payload?.data
          },
          id: payload.apiId
        });
        response.content &&
          response.content.map((item) => {
            item['driverName'] = item?.driver?.name;
            item['mechanicName'] = item?.mechanic?.name;
            item['unitId'] = item?.unit_id?.name;
            item['vin'] = item?.vin?.name;
          });
        if(response) {
          this.listSuccess(response.content);
          this.saveTotalCount(response.totalElements)
        }
      } catch (error) {
        this.listFailed();
      }
    },
    async fetchAddData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('getAddWorkOrdersApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject
          },
          id: payload.apiId
        });
        response && this.listAddSuccess(response);
      } catch (error) {
        this.listFailed();
      }
    },
    async saveAddData(payload, rootState) {
      try {
        const getApi = await import(
          `../../../config/apis/${window?.$environment?.SERVICE_PROVIDER}`
        );
        const apiObject = getApi.default('saveAddWorkOrdersApi');
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: apiObject,
            data: payload.data
          },
          id: payload.apiId
        });
        payload.callback &&
          payload.callback(
            'success',
            'Work Order Added Successfully',
            () => payload.history && payload.history.push(pageUrls.workOrders)
          );
      } catch (error) {
        // this.listFailed();
        payload.callback && payload.callback('warning', error.message);
      }
    },
    async saveDocument(payload) {
      try {
        const data = payload.data.data;
        const response = await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: documentUploadApi,
            data: data
          },
          id: payload.apiId
          // callback: payload.callback
        });
        response && this.updateLocalDocumentList({ response }, data);
      } catch (error) {
        // this.saveFailed();
      }
    },
    async deleteDocument(payload) {
      try {
        const url = documentDeleteApi.url.split('{')[0];
        await dispatch.asyncRequests.handleAsyncRequests({
          payload: {
            api: { ...documentDeleteApi, url: url + payload.data.id }
          },
          id: payload.apiId
        });
        this.updateLocalDocumentList({
          isDelete: true,
          id: payload.data.id
        });
      } catch (error) {
        // this.deleteFailed();
      }
    }
  })
};
