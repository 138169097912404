import { useTheme } from '@material-ui/core';
import React, { lazy } from 'react';
import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;

const DonutChart = lazy(() => import(`@/components/UI/Graph/DonutChart/${layout}`));

const DonutGraphInfo = ({
    backgroundColor = null,
    borderColor= null,
    data = null,
    centerTextData = null,
    small = false,
    labels = null,
    onClick = null,
    hideHover= false,
    ...rest
}) => {
    const theme = useTheme();
    return (
        <Styled.Wrapper>
             <DonutChart
                {...rest}
                width={"180px"}
                height={"180px"}
                backgroundColor={backgroundColor}
                borderColor={borderColor || backgroundColor}
                data={data}
                hideHover={hideHover}
                areagraph
                title={''}
                theme={theme}
                onClick={onClick}
                labels={labels}
                small={small}
                centerTextData={centerTextData}
                noHyperLink>
            </DonutChart>
        </Styled.Wrapper>
    );
};


DonutGraphInfo.propTypes = {

};


export default DonutGraphInfo;
