import { imageUrls } from '@/config/constants/keys';
import { checkIsAdmin, getFilterLocaleValues, getImagePath, hasEnabledFeature, loadStyle } from '@/utils/utils';
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Box } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Switch } from 'antd';
import { drag, event, select } from 'd3';
import moment from 'moment';
import React, { lazy, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ParentStyled from './style';

let Styled = loadStyle(ParentStyled, 'components/modules/DailyLog');

const layout = window?.$environment?.CURRENT_LAYOUT;
const SuccessIcon = lazy(() => import(`@/components/SuccessIcon/${layout}`));

const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`);
const DailyLog = ({
  gridWidth,
  gridHeight,
  origin,
  yGap,
  viewBox,
  yData,
  xData,
  dataList: propsData,
  setDailyLogEntries,
  originalData,
  assignedDriver,
  viewOnly,
  setFinalListForUpdate,
  finalListForUpdate,
  udEdit,
  drivers,
  unformattedDriverList,
  enableEditOW,
  dailyLogDetails,
  providerMode, setProviderMode,
  getDailyLogDetails
}) => {
  const isAdmin = checkIsAdmin();
  const match = useParams();
  const { i18n } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    if (propsData) setDataList(propsData)
  }, [propsData])
  //Grid cell size
  const gridCellWidth = gridWidth || 13;
  const gridCellHeight = gridHeight || 13;
  const statusList = [
    { value: 'OFF', label: 'OFF_DUTY' },
    { value: 'SB', label: 'SLEEPER_BERTH' },
    { value: 'DR', label: 'DRIVING' },
    { value: 'ON', label: 'ON_DUTY' }
  ];

  //Graph origin
  const leftTopCoordinate = origin || { x: 10, y: 10 };
  //Graph width and height
  const graphHeight = gridCellHeight * yData.length;
  const graphWidth = gridCellWidth * (xData.length - 1);
  const svgWidth = gridCellWidth * (xData.length - 1) + leftTopCoordinate.x;

  //Y gap from origin to first status text
  const extraYGapInStatusLine = yGap || 5;

  //To display x and y axis texts
  const yAxis = { x: 2, y: gridCellHeight };
  const xAxis = { x: gridCellWidth, y: 4 };
  const svgViewBox = enableEditOW ? '0 0 335 89' : '0 0 335 75';

  //Y axis values
  const [yValues, setYValues] = useState([]);

  useEffect(() => {
    setYValues(
      yData.map((data, index) => {
        return {
          key: data.value,
          text: data.label,
          x: yAxis.x,
          y: (index + 1) * yAxis.y + 5
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yData]);
  const startTime = '12:00am';
  const endTime = '12:00am';

  //X axis values
  const xValues = xData.map((data, index) => {
    return {
      text: data,
      x: (index + 1) * xAxis.x - 4,
      y: xAxis.y
    };
  });

  // Left bottom triangle to drag
  let leftPoly = [
    { x: leftTopCoordinate.x, y: graphHeight + 15 },
    { x: leftTopCoordinate.x - 5, y: graphHeight + 23 },
    { x: leftTopCoordinate.x + 5, y: graphHeight + 23 }
  ];

  // Right bottom triangle to drag
  let rightPoly = [
    { x: svgWidth, y: graphHeight + 15 },
    { x: svgWidth - 5, y: graphHeight + 23 },
    { x: svgWidth + 5, y: graphHeight + 23 }
  ];
  const topStatus = yData[0];

  //Newly added rows
  const [editedDataList, setEditedDataList] = useState([]);

  //Selected row
  const [selectedRow, setSelectedRow] = useState(-1);
  const [newRow, setNewRow] = useState(0);

  // Initial value for edit overlay area
  const [editingArea, setEditingArea] = useState({
    lx: leftTopCoordinate.x,
    ly: leftTopCoordinate.y + extraYGapInStatusLine,
    ltime: startTime,
    rx: svgWidth,
    ry: leftTopCoordinate.y + extraYGapInStatusLine,
    rtime: endTime
  });
  let currentDraggedData = {};
  const [isDragged, setIsDragged] = useState(false);
  const [canEditRowByGraph, setCanEditRowByGraph] = useState(true);
  //Values to plot the step graph
  const [stepGraphPoints, setStepGraphPoints] = useState([
    {
      x: leftTopCoordinate.x,
      y: 15,
      time: startTime,
      status: { ...topStatus },
      note: '',
      stateModifier: {
        label: i18n.t('common.pleaseSelect'),
        value: 'please'
      },
      location: '',
      driverId: { ...assignedDriver },
      readOnly: false
    },
    {
      x: svgWidth,
      y: leftTopCoordinate.y + extraYGapInStatusLine,
      time: endTime,
      status: { ...topStatus },
      note: '',
      stateModifier: {
        label: i18n.t('common.pleaseSelect'),
        value: 'please'
      },
      location: '',
      driverId: { ...assignedDriver },
      readOnly: false
    }
  ]);

  const updateField = (payload) => {
    let source = [...finalListForUpdate];
    source = source.map((item) => {
      if (
        (!item[payload.field]?.label && payload.field === 'driverId') ||
        payload.field === 'coDriverId'
      ) {
        item[payload.field] = payload.data;
      }
      if (
        payload.data &&
        !item[payload.field] &&
        (payload.field === 'location' || payload.field === 'note')
      ) {
        item[payload.field] = payload.data;
      }
      return item;
    });
    setFinalListForUpdate(source);
  };

  useEffect(() => {
    let result = [];

    if (dataList && dataList.length && yValues.length) {
      dataList.map((item, index) => {
        const stateModifier = item.oilFieldWaiting
          ? item.stateModifier
          : item.status;
        const y = getObjectKeyValue(yValues, 'text', stateModifier, 'y') - 3;
        const statusKey = getObjectKeyValue(
          yValues,
          'text',
          item.status,
          'key'
        );
        result.push({
          x: item.time_from === startTime && item.minutes_from !== 1440
            ? leftTopCoordinate.x
            : item.minutes_from == 1440 ?
              findGraphPointsFromTime('11:59pm')
              : item.time_from
                ? findGraphPointsFromTime(item.time_from)
                : '',
          y,
          time: item.time_from,
          status: {
            label: item.status,
            value: statusKey
          },
          note: item.note,
          stateModifier: (item.stateModifier && {
            label:
              window.$environment.SERVICE_PROVIDER === 'clutch'
                ? 'Yes'
                : item.stateModifier,
            value: 'OW'
          }) || {
            label:
              window.$environment.SERVICE_PROVIDER === 'clutch'
                ? 'No'
                : i18n.t('common.pleaseSelect'),
            value: 'please'
          },
          oilFieldWaiting: item.oilFieldWaiting,
          location: item.location,
          driverId:
            udEdit && item.driverId
              ? {
                label:
                  unformattedDriverList &&
                  unformattedDriverList[item.driverId],
                value: item.driverId
              }
              : item.driverId === 0
                ? { label: i18n.t('common.pleaseSelect'), value: 0 }
                : { ...assignedDriver },
          readOnly: item.readOnly,
          udReadOnly: item.udReadOnly,
          eldData: item.eldData,
          key: item.id,
          minutes_from: item.minutes_from,
          minutes_to: item.minutes_to
        })
        result.push({
          x:
            item.time_to === startTime && item.minutes_to === 0
              ? leftTopCoordinate.x : item.time_to === endTime ?
                svgWidth :
                item.minutes_to >= 1440 ?
                  findGraphPointsFromTime('11:59pm')
                  : item.time_to
                    ? findGraphPointsFromTime(item.time_to)
                    : '',
          y,
          time: item.time_to,
          status: {
            label: item.status,
            value: statusKey
          },
          note: item.note,
          stateModifier: (item.stateModifier && {
            label:
              window.$environment.SERVICE_PROVIDER === 'clutch'
                ? 'Yes'
                : item.stateModifier,
            value: 'OW'
          }) || {
            label:
              window.$environment.SERVICE_PROVIDER === 'clutch'
                ? 'No'
                : i18n.t('common.pleaseSelect'),
            value: 'please'
          },
          oilFieldWaiting: item.oilFieldWaiting,
          location: item.location,
          driverId: udEdit
            ? {
              label:
                unformattedDriverList && unformattedDriverList[item.driverId],
              value: item.driverId
            }
            : { ...assignedDriver },
          readOnly: item.readOnly,
          udReadOnly: item.udReadOnly,
          eldData: item.eldData,
          key: item.id,
          minutes_to: item?.minutes_to,
          minutes_from: item.minutes_from
        })
      });
      // if (!udEdit) {
      //   setStepGraphPoints([...result]);

      // }
      // setEditedDataList([...result]);
      // setFinalListForUpdate([...result]);
      if (udEdit) {
        result = result.map((item) => {
          if (window.location.href.includes('/0/')) {
            return item.status?.label === 'DRIVING' &&
              item.driverId?.label !== 'Unidentified Driver'
              ? item
              : null;
          } else
            return item.status?.label === 'DRIVING' &&
              item.driverId?.label === 'Unidentified Driver'
              ? item
              : null;
        });
        result = result.filter((item) => item);

        setEditedDataList([...result]);
        setFinalListForUpdate([...result]);
      } else {
        let filteredPoints = result.filter(o => {
          return ((o.minutes_to <= 1440 || !o.minutes_to) && (o.minutes_from <= 1440 || !o.minutes_from)) || (o.minutes_to >= 1440 || !o.minutes_to) && o.minutes_from <= 1440
        })

        setStepGraphPoints([...filteredPoints]);
        setEditedDataList([...result]);
        setFinalListForUpdate([...result]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataList, yValues, providerMode]);

  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => { }
  });
  const buttonStatusStyle =
    selectedRow !== -1 ? 'button--status-normal' : 'button--disabled';
  const [buttonUpdateStyle, setButtonUpdateStyle] = useState(
    selectedRow !== -1 ? 'button--update-normal' : 'button--disabled'
  );
  const [buttonUpdateGraphStyle, setButtonUpdateGraphStyle] = useState(
    'button--update-normal'
  );
  //Update when editing area changes
  useEffect(() => {
    const stepGraph = select('svg > .stepGraph')._groups[0][0];
    //If edit icon gets clicked
    if (stepGraph !== null) {
      changeStatusLine(null);
    }

    if (selectedRow > -1) {
      yValues.map((y) => {
        const node = document.getElementById(y.key);
        node.className = node.className.replace('active', '');
      });
      const status = getStatusFromPoint(
        getObjectKeyValue(yValues, 'y', editingArea.ly + 3, 'key')
      );
      const node = document.getElementById(status.value);
      node.className = node.className + ' active';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingArea]);

  //Update based on the graph values
  useEffect(() => {
    const stepGraph = select('svg > .stepGraph')._groups[0][0];
    //If page load happens
    if (stepGraph === null && yValues.length) {
      generateGrid();
    }
    //Re draw graph when change step points
    const hasUpdated = hasEditedTheList();
    if (!hasUpdated && yValues.length) drawGraph();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepGraphPoints]);

  //Get the value of a key from a list based on other key value
  const getObjectKeyValue = (source, key, value, requiredField) => {
    for (let index = 0; index < source.length; index++) {
      const element = source[index];
      if (element[key] === value) {
        return element[requiredField];
      }
    }
  };

  //Get status label of a status key
  const getStatusFromPoint = (value) => {
    const label = getObjectKeyValue(yData, 'value', value, 'label');
    return {
      label,
      value
    };
  };

  //Generate values to draw the grid
  const gridData = () => {
    let data = [];
    let xpos = leftTopCoordinate.x;
    let ypos = leftTopCoordinate.y;

    // iterate for rows
    for (let row = 0; row < yData.length; row++) {
      data.push([]);
      // iterate for cells/columns inside rows
      for (let column = 0; column < xData.length - 1; column++) {
        data[row].push({
          x: xpos,
          y: ypos,
          width: gridCellWidth,
          height: gridCellHeight,
          row: row,
          yValue: yValues[row],
          xValue: xValues[column]
        });
        // increment the x position. I.e. move it over by width variable
        xpos += gridCellWidth;
      }
      // reset the x position after a row is complete
      xpos = leftTopCoordinate.x;
      // increment the y position for the next row. Move it down height variable
      ypos += gridCellHeight;
    }
    return data;
  };

  //Co ordinates for draggable stick
  const getStickCoordinate = (current, next) => {
    let x = current;
    if (next <= leftTopCoordinate.x) {
      x = leftTopCoordinate.x;
    } else if (next >= graphWidth) {
      x = svgWidth;
    } else {
      x = next;
    }
    return x;
  };

  const getTimeFromXValue = (x) => {
    //Find the time corresponding to stick position
    const oneHr = gridCellWidth;
    const oneMinute = gridCellWidth / 60;
    const hrs = Math.floor((x - leftTopCoordinate.x) / oneHr);

    const minutes = Math.floor(
      (x - leftTopCoordinate.x) / oneMinute - hrs * 60
    );
    let currentTime = `${hrs < 12 ? hrs : Math.abs(12 - hrs)}:${minutes < 10 ? 0 : ''
      }${minutes}${hrs < 12 ? 'am' : 'pm'}`;
    if (currentTime === '0:00am' || currentTime === '12:00pm') {
      currentTime = '12:00am';
    }
    currentTime = timeInZero(currentTime);
    return currentTime;
  };

  const timeInTwelve = (time) => {
    const isTimeInTwelve =
      time.split(':')[0] === '12' && !time.includes('12:00');
    return isTimeInTwelve ? time.replace('12:', '0:') : time;
  };

  const timeInZero = (time) => {
    const timeInZero = time.split(':')[0] === '0';
    return timeInZero ? time.replace('0:', '12:') : time;
  };

  const findGraphPointsFromTime = (time) => {
    //Find the time corresponding to stick position
    if (time) {
      const oneHr = gridCellWidth;
      const oneMinute = gridCellWidth / 60;
      time = timeInTwelve(time);
      const hrs = parseInt(time.split(':')[0], 10);

      const minutes = parseInt(time.split(':')[1].substring(0, 2), 10);
      const timeIn24 =
        time.includes('pm') > 0 && time !== '12:00pm' ? hrs + 12 : hrs;

      return timeIn24 * oneHr + minutes * oneMinute + leftTopCoordinate.x;
    } else return '';
  };

  const checkIfEdited = (source) => {
    let needUpdate = false;
    let temp = [];
    for (let index = 0; index < source.length; index++) {
      let point = source[index];
      let isEdited = point.time.includes('edited') > 0;
      if (point.deleted) {
        if (source[index + 1]?.deleted) {
          if (index === 0) {
            temp[0] = {
              ...source[2],
              x: point.x,
              time: point.time
            };
            temp[1] = {
              ...source[3]
            };
            index = 3;
          } else if (index === source.length - 2) {
            temp[index - 1] = {
              ...temp[index - 1],
              x: source[index + 1].x,
              time: source[index + 1].time
            };
          } else {
            temp[index - 1] = {
              ...temp[index - 1],
              x: source[index + 2].x,
              time: source[index + 2].time
            };
          }
          needUpdate = true;
        } else {
        }
      } else if (isEdited) {
        const time = point.time.replace('edited', '');
        point = {
          ...point,
          x: findGraphPointsFromTime(time),
          time: time
        };
        temp.push(point);
        needUpdate = true;
      } else if (point.status.edited) {
        point = {
          ...point,
          status: { value: point.status.value, label: point.status.label }
        };
        temp.push(point);

        needUpdate = true;
      } else {
        temp.push(point);
      }
    }

    return { needUpdate, temp };
  };

  const hasEditedTheList = () => {
    let isEdited = false;
    for (let index = 0; index < editedDataList.length; index++) {
      const element = editedDataList[index];
      isEdited = element.time?.includes('edited') > 0;
      if (isEdited) {
        return isEdited;
      } else if (element.status.edited) {
        isEdited = true;
        return isEdited;
      } else if (element.deleted) {
        isEdited = true;
        return isEdited;
      }
    }
    return isEdited;
  };

  //Draw grid
  const generateGrid = () => {
    const svg = select('div#chartContainer')
      .append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', svgViewBox)
      .attr('class', 'svg-content');
    svg
      .selectAll('.yValue')
      .data(yValues)
      .enter()
      .append('text')
      .attr('x', function (d) {
        return d.x;
      })
      .attr('y', function (d) {
        return d.y;
      })
      .text(function (d) {
        return i18n.t(d.key);
      })
      .attr('font-size', '0.3rem')
      .attr('fill', theme.palette.grey.color);
    svg
      .selectAll('.xValue')
      .data(xValues)
      .enter()
      .append('text')
      .attr('x', function (d) {
        return d.x;
      })
      .attr('y', function (d) {
        return d.y;
      })
      .text(function (d) {
        return d.text;
      })
      .attr('font-size', '0.3rem')
      .attr('fill', theme.palette.grey.color);

    const row = svg
      .selectAll('.row')
      .data(gridData())
      .enter()
      .append('g')
      .attr('class', 'row');

    const column = row
      .selectAll('.column')
      .data(function (d) {
        return d;
      })
      .enter()
      .append('g');

    column
      .append('rect')
      .attr('class', 'square')
      .attr('x', function (d) {
        return d.x;
      })
      .attr('y', function (d) {
        return d.y;
      })
      .attr('width', function (d) {
        return d.width;
      })
      .attr('stroke', theme.palette.grey.color)
      .style('stroke-width', '0.4')

      .attr('height', function (d) {
        return d.height;
      });

    column
      .append('line')
      .attr('x1', function (d) {
        return d.x + d.width / 4;
      })
      .attr('y2', function (d) {
        return d.y + d.height;
      })
      .attr('x2', function (d) {
        return d.x + d.width / 4;
      })
      .attr('y1', function (d) {
        return d.y + (d.height * 3) / 4;
      })
      .attr('stroke', theme.palette.grey.color)
      .style('stroke-width', '0.5');
    column
      .append('line')
      .attr('x1', function (d) {
        return d.x + d.width / 2;
      })
      .attr('y2', function (d) {
        return d.y + d.height;
      })
      .attr('x2', function (d) {
        return d.x + d.width / 2;
      })
      .attr('y1', function (d) {
        return d.y + d.height / 2;
      })
      .attr('stroke', theme.palette.grey.color)
      .style('stroke-width', '0.5');

    column
      .append('line')
      .attr('x1', function (d) {
        return d.x + 3 * (d.width / 4);
      })
      .attr('y2', function (d) {
        return d.y + d.height;
      })
      .attr('x2', function (d) {
        return d.x + 3 * (d.width / 4);
      })
      .attr('y1', function (d) {
        return d.y + (d.height * 3) / 4;
      })
      .attr('stroke', theme.palette.grey.color)
      .style('stroke-width', '0.5');
  };

  const findMinutesFromTime = (time) => {
    if (time) {
      const hrs = parseInt(time.split(':')[0], 10);

      const minutes = parseInt(time.split(':')[1].substring(0, 2), 10);
      const timeIn24 =
        time.includes('pm') > 0 && hrs != 12 ? hrs + 12 : time === '12:00am' ? 0 : hrs;
      return (timeIn24 * 60) + minutes
    } else {
      return 0
    }
  }
  const resetDataList = (source) => {
    let result = [];
    for (let i = 0; i < source.length; i++) {
      const element = source[i];
      if (i % 2 === 0) {
        delete element.noEdit;
        result[result.length] = {
          status: element.status.label,
          note: element.note || null,
          stateModifier:
            element?.stateModifier !== null &&
              element?.stateModifier?.value !== 'please'
              ? element?.stateModifier?.label || null
              : null,
          oilFieldWaiting:
            element?.stateModifier !== null &&
              element?.stateModifier?.value !== 'please' &&
              hasEnabledFeature('editOilFieldWaitingEnabled') &&
              dailyLogDetails?.originalData?.enableEditOW === true
              ? true
              : false,
          location: element.location || null,
          driverId: parseInt(element?.driverId?.value, 10) || null,
          time_from: element.time,
          eldData: element.eldData,
          time_to: source[i + 1].time,
          readOnly: element.readOnly,
          minutes_to: element.minutes_to >= 1440 ? element.minutes_to : findMinutesFromTime(source[i + 1].time),
          minutes_from: element.minutes_from >= 1440 ? element.minutes_from : findMinutesFromTime(element.time)

        };
      }
    }
    if (result.length) result[result.length - 1].minutes_to = 1440
    result.sort((a, b) => { return a.minutes_to - b.minutes_to })
    result.forEach((ele, index) => {
      if (index == 0) {
        ele.minutes_from = 0
      }
      if (result[index + 1]) {
        result[index + 1].minutes_from = ele.minutes_to
      }
      ele.time_from = findTimeFromMinutes(ele.minutes_from)
      ele.time_to = findTimeFromMinutes(ele.minutes_to)
    })
    return result;
  };

  const findTimeFromMinutes = (minutes) => {
    let hr = Math.floor(minutes / 60)
    let actualHr = hr ? hr > 12 ? hr - 12 : hr : '12'
    let min = minutes % 60;
    min = min > 9 ? min : `0${min}`
    return `${actualHr}:${min}${hr >= 12 && hr != 24 ? 'pm' : 'am'}`
  }

  const updateGraph = (needUpdate, temp) => {
    //If all times are entered
    if (validateRow()) {
      if (selectedRow > -1) {
        getNewGraphPoints(editingArea);
        setIsDragged(false);
      } else if (needUpdate || temp.length) {
        const result = resetDataList([...temp]);
        setDailyLogEntries({
          data: [...result],
          asArray: true
        });
      }

      //Clear all after an event update
      select('svg > .overlay').remove();

      setSelectedRow(-1);
      setNewRow(0);
      setButtonUpdateStyle('button--disabled');
      setButtonUpdateGraphStyle('button--update-normal');
      setCanEditRowByGraph(true);
    }
  };

  const drawGraph = () => {
    //Clear existing svg
    select('svg > .overlay').remove();
    select('svg > .stepGraph').remove();

    select('.svg-content')
      .append('g')
      .attr('class', 'stepGraph')
      .data([stepGraphPoints])
      .append('polyline')
      .attr('points', function (d) {
        return d
          .map(function (d) {
            return [d.x, d.y].join(',');
          })
          .join(' ');
      })
      .attr('stroke', theme.palette.secondary.main)
      .attr('fill', 'none')
      .style('stroke-width', '1.5');
    // }
  };

  const changeStatusLine = (event) => {
    //Clear previous overlay
    select('svg > .overlay').remove();

    let statusLine;

    if (event && event.currentTarget) {
      // Individual status button click for add a row
      statusLine = event.currentTarget.dataset.value - 3;
    } else {
      //from useEffect when edit icon clicks
      statusLine = editingArea.ly;
    }
    //If status button clicks
    if (event) {
      setEditingArea({
        ...editingArea,
        ly: statusLine,
        ry: statusLine
      });
    }
    //from useEffect when edit icon clicks or change status after drag the area
    if (isDragged || event === null) {
      const overlay = select('.svg-content')
        .append('g')
        .attr('class', 'overlay');
      overlay
        .append('line')
        .attr('class', 'statusBar')
        .attr('x1', () => editingArea.lx)
        .attr('y1', () => statusLine)
        .attr('x2', () => editingArea.rx)
        .attr('y2', () => statusLine)
        .attr('stroke', theme.palette.secondary.main)
        .style('stroke-width', '1.5');
      overlay
        .append('rect')
        .attr('class', 'selectedArea')
        .attr('x', () => editingArea.lx)
        .attr('y', () => leftTopCoordinate.y)
        .attr('width', editingArea.rx - editingArea.lx)
        .attr('height', () => graphHeight)
        .attr('stroke', 'none')
        .style('fill', 'rgba(245,130,32, 0.5)');

      overlay
        .append('line')
        .attr('class', 'leftStick')
        .attr('x1', () => editingArea.lx)
        .attr('y1', () => leftTopCoordinate.y)
        .attr('x2', () => editingArea.lx)
        .attr('y2', () => graphHeight + 21)
        .attr('stroke', theme.palette.secondary.main)
        .style('stroke-width', '0.5');
      const leftChip = overlay.append('g');

      leftChip
        .append('rect')
        .attr('class', 'leftChip')
        .attr('x', () => editingArea.lx - 9)
        .attr('y', () => leftTopCoordinate.y + 10)
        .attr('width', () => 19)
        .attr('height', () => 9)
        .attr('stroke', theme.palette.secondary.main)
        .style('stroke-width', '0.7')
        .style('rx', '2')
        .style('fill', 'colors.WHITE');
      leftChip
        .append('text')
        .attr('class', 'leftTime')

        .attr('x', function (d) {
          return editingArea.lx - 6.5;
        })
        .attr('y', function (d) {
          return leftTopCoordinate.y + 16;
        })
        .text(function (d) {
          return editingArea.ltime.replace('edited', '');
        })
        .attr('font-size', '0.3rem')
        .attr('fill', 'colors.PRIMARY_CONTRAST');

      leftPoly[0].x = editingArea.lx;
      leftPoly[1].x = editingArea.lx - 5;
      leftPoly[2].x = editingArea.lx + 5;

      overlay
        .data([leftPoly])
        .append('polygon')
        .attr('class', 'leftPoly')
        .attr('points', (d) => d.map((d) => [d.x, d.y].join(',')).join(' '))
        .attr('transform', 'translate(' + 0 + ',' + 0 + ')')
        .attr('fill', theme.palette.secondary.main)

        .call(
          drag()
            .on('start', dragStarted)
            .on('drag', leftDragged)
            .on('end', dragEnded)
        );
      overlay
        .append('line')
        .attr('class', 'rightStick')
        .attr('x1', () => editingArea.rx)
        .attr('y1', () => leftTopCoordinate.y)
        .attr('x2', () => editingArea.rx)
        .attr('y2', () => graphHeight + 21)
        .attr('stroke', theme.palette.secondary.main)
        .style('stroke-width', '0.5');
      const rightChip = overlay.append('g');

      rightChip
        .append('rect')
        .attr('class', 'rightChip')
        .attr('x', () => editingArea.rx - 10)
        .attr('y', () => leftTopCoordinate.y + 10)
        .attr('width', () => 19)
        .attr('height', () => 9)
        .attr('stroke', theme.palette.secondary.main)
        .style('stroke-width', '0.7')
        .style('rx', '2')

        .style('fill', 'colors.WHITE');
      rightChip
        .append('text')
        .attr('class', 'rightTime')

        .attr('x', function (d) {
          return editingArea.rx - 9;
        })
        .attr('y', function (d) {
          return leftTopCoordinate.y + 16;
        })
        .text(function (d) {
          return editingArea.rtime.replace('edited', '');
        })
        .attr('font-size', '0.3rem')
        .attr('fill', 'colors.PRIMARY_CONTRAST');

      rightPoly[0].x = editingArea.rx;
      rightPoly[1].x = editingArea.rx - 5;
      rightPoly[2].x = editingArea.rx + 5;
      overlay
        .data([rightPoly])
        .append('polygon')
        .attr('class', 'rightPoly')
        .attr('points', (d) => d.map((d) => [d.x, d.y].join(',')).join(' '))
        .attr('transform', 'translate(' + 0 + ',' + 0 + ')')
        .attr('fill', theme.palette.secondary.main)
        .call(
          drag()
            .on('start', dragStarted)
            .on('drag', rightDragged)
            .on('end', dragEnded)
        );
    }
  };

  const dragStarted = (d) => {
    if (canEditRowByGraph) {
      //Get current editing area points
      currentDraggedData = { ...editingArea };
      setIsDragged(true);
      select(this).raise().classed('active', true);
    }
  };

  const rightDragged = (d) => {
    canEditRowByGraph && dragged(d, '.rightStick', '.rightPoly');
  };

  const leftDragged = (d) => {
    canEditRowByGraph && dragged(d, '.leftStick', '.leftPoly');
  };

  const dragged = (draggedData, className, polyClassName) => {
    //Find the position after moving left or right stick
    const x = getStickCoordinate(draggedData[0].x, event.x);
    let width =
      className === '.leftStick' ? editingArea.rx - x : editingArea.lx - x;
    //Disable if the sticks cross each other
    if (
      (width > 0 && className === '.leftStick') ||
      (width < 0 && className === '.rightStick')
    ) {
      const currentTime = getTimeFromXValue(x);

      //Redraw the overlay based on the selection
      select(className)
        .attr('x1', () => x)
        .attr('x2', () => x);
      select(polyClassName).attr('points', () =>
        [
          [x, draggedData[0].y],
          [x - 5, draggedData[1].y],
          [x + 5, draggedData[2].y]
        ].join(' ')
      );

      if (className === '.leftStick') {
        select('.selectedArea')
          .attr('x', () => x)
          .attr('width', () => currentDraggedData.rx - x);
        select('.statusBar').attr('x1', () => x);
        select('.leftChip').attr('x', () => x - 10);
        select('.leftTime')
          .attr('x', () => x - 8)
          .text(() => currentTime);
        currentDraggedData = {
          ...currentDraggedData,
          lx: x,
          ltime: currentTime
        };
      } else {
        select('.selectedArea').attr('width', () => x - currentDraggedData.lx);
        select('.statusBar').attr('x2', () => x);
        select('.rightChip').attr('x', () => x - 10);
        select('.rightTime')
          .attr('x', () => x - 8)
          .text(() => currentTime);
        currentDraggedData = {
          ...currentDraggedData,
          rx: x,
          rtime: currentTime
        };
      }
    }
  };

  const dragEnded = (d) => {
    if (canEditRowByGraph) {
      setEditingArea({ ...currentDraggedData });
      currentDraggedData = {};
      select(this).classed('active', false);
    }
  };
  const getNextPoint = (list) => {
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.x > editingArea.rx) {
        return list[index - 1];
      }
    }
  };
  const getPrevPoint = (list) => {
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.x < editingArea.rx) {
        return list[index + 1];
      }
    }
  };
  const checkServiceProvider = (currentStatusValue) => {
    if (window.$environment.SERVICE_PROVIDER !== 'clutch') {
      if (currentStatusValue?.value === 'OW') {
        return {
          label: 'OIL_FIELD_WAITING',
          value: 'OW'
        };
      } else {
        return {
          label: 'No',
          value: 'please'
        };
      }
    } else {
      if (currentStatusValue?.value === 'OW') {
        return {
          label: 'Yes',
          value: 'OW'
        };
      } else {
        return {
          label: 'No',
          value: 'please'
        };
      }
    }
  };
  //Find the new graph points based on overlay position
  const getNewGraphPoints = (editingArea) => {
    let temp = [...editedDataList];
    const currentStartingPoint = temp[selectedRow ? selectedRow * 2 : 0];
    currentStartingPoint.minutes_to = currentStartingPoint.minutes_to > 1440 ? currentStartingPoint.minutes_to : findMinutesFromTime(editingArea.ltime)
    const currentFinishingPoint = temp[selectedRow ? selectedRow * 2 + 1 : 1];
    if (editingArea.ltime === '12:00am' && editingArea.rtime === '12:00am') {
      temp = [];

      temp.push({
        ...currentStartingPoint,
        time: editingArea.ltime,
        x: editingArea.lx
      });
      temp.push({
        ...currentFinishingPoint,
        time: editingArea.rtime,
        x: editingArea.rx
      });
    } else {
      const nextToLastPoint = getNextPoint(temp);
      const prevToFirstPoint = getPrevPoint(temp);

      const currentStatusValue = getStatusFromPoint(
        getObjectKeyValue(yValues, 'y', editingArea.ly + 3, 'key')
      );
      const currentStatus =
        currentStatusValue?.value === 'OW'
          ? { label: i18n.t('OFF_DUTY'), value: 'OFF' }
          : currentStatusValue;
      const currentStateModifier = checkServiceProvider(currentStatusValue);
      const currentOilFieldWaiting =
        currentStatusValue?.value === 'OW' ? true : false;

      let firstNewPoint = {
        ...currentStartingPoint,
        time: editingArea.ltime,
        x: editingArea.lx,
        minutes_to: currentStartingPoint.minutes_to > 1440 ? currentStartingPoint.minutes_to : findMinutesFromTime(editingArea.ltime)

      };
      let secondNewPoint = {
        ...firstNewPoint,
        time: editingArea.ltime,
        x: editingArea.lx,
        status: currentStatus,
        stateModifier: currentStateModifier,
        oilFieldWaiting: currentOilFieldWaiting,
        y: editingArea.ly
      };
      let lastNewPoint = {
        ...currentFinishingPoint,
        time: editingArea.rtime,
        x: editingArea.rx
      };
      let secondLastNewPoint = {
        ...currentFinishingPoint,
        time: editingArea.rtime,
        x: editingArea.rx,
        status: currentStatus,
        stateModifier: currentStateModifier,
        oilFieldWaiting: currentOilFieldWaiting,

        y: editingArea.ly
      };

      if (
        currentStartingPoint.x !== firstNewPoint.x &&
        currentFinishingPoint.x !== lastNewPoint.x
      ) {
        //Change in both x, change in y
        firstNewPoint = {
          ...firstNewPoint,
          time: editingArea.ltime,
          x: editingArea.lx,
          status: prevToFirstPoint
            ? prevToFirstPoint.status
            : firstNewPoint.status,
          stateModifier: prevToFirstPoint
            ? prevToFirstPoint.stateModifier
            : firstNewPoint.stateModifier,
          oilFieldWaiting: prevToFirstPoint
            ? prevToFirstPoint.oilFieldWaiting
            : firstNewPoint.oilFieldWaiting,

          y: prevToFirstPoint ? prevToFirstPoint.y : firstNewPoint.y
        };

        secondNewPoint = {
          ...secondNewPoint,
          status: currentStatus,
          stateModifier: currentStateModifier,
          oilFieldWaiting: currentOilFieldWaiting,

          y: editingArea.ly
        };
        temp.splice(selectedRow ? selectedRow * 2 + 1 : 1, 0, firstNewPoint);
        temp.splice(selectedRow ? selectedRow * 2 + 2 : 2, 0, secondNewPoint);

        secondLastNewPoint = {
          ...secondLastNewPoint,
          status: currentStatus,
          stateModifier: currentStateModifier,
          oilFieldWaiting: currentOilFieldWaiting,

          y: editingArea.ly
        };

        temp.splice(
          selectedRow ? selectedRow * 2 + 3 : 3,
          0,
          secondLastNewPoint
        );

        lastNewPoint = {
          ...lastNewPoint,
          time: editingArea.rtime,
          x: editingArea.rx,
          status: nextToLastPoint
            ? nextToLastPoint.status
            : lastNewPoint.status,
          eldData: nextToLastPoint
            ? nextToLastPoint.eldData
            : lastNewPoint.eldData,
          ...(providerMode ? {
            location: nextToLastPoint
              ? nextToLastPoint.location
              : lastNewPoint.location,
            note: nextToLastPoint
              ? nextToLastPoint.note
              : lastNewPoint.note,
            readOnly: nextToLastPoint
              ? nextToLastPoint.readOnly
              : lastNewPoint.readOnly,
          } : {}),
          stateModifier: nextToLastPoint
            ? nextToLastPoint.stateModifier
            : lastNewPoint.stateModifier,
          oilFieldWaiting: nextToLastPoint
            ? nextToLastPoint.oilFieldWaiting
            : lastNewPoint.oilFieldWaiting,
          y: nextToLastPoint ? nextToLastPoint.y : lastNewPoint.y
        };
        temp.splice(selectedRow ? selectedRow * 2 + 4 : 4, 0, lastNewPoint);
      } else if (
        currentStartingPoint.x === firstNewPoint.x &&
        currentFinishingPoint.x === lastNewPoint.x &&
        firstNewPoint.y === lastNewPoint.y
      ) {
        //No change in x. but y may get changed
        firstNewPoint = {
          ...firstNewPoint,
          status: currentStatus,
          stateModifier: currentStateModifier,
          oilFieldWaiting: currentOilFieldWaiting,
          y: editingArea.ly
        };
        lastNewPoint = {
          ...lastNewPoint,
          status: currentStatus,
          stateModifier: currentStateModifier,
          oilFieldWaiting: currentOilFieldWaiting,
          y: editingArea.ly
        };
        temp[selectedRow ? selectedRow * 2 : 0] = firstNewPoint;
        temp[selectedRow ? selectedRow * 2 + 1 : 1] = lastNewPoint;
      } else if (
        currentStartingPoint.x === firstNewPoint.x &&
        currentFinishingPoint.x !== lastNewPoint.x
      ) {
        //No change in lx. y or rx is changed
        firstNewPoint = {
          ...firstNewPoint,
          status: currentStatus,
          stateModifier: currentStateModifier,
          oilFieldWaiting: currentOilFieldWaiting,
          y: editingArea.ly
        };
        secondLastNewPoint = {
          ...secondLastNewPoint,
          status: currentStatus,
          stateModifier: currentStateModifier,
          oilFieldWaiting: currentOilFieldWaiting,
          y: editingArea.ly
        };

        temp[selectedRow ? selectedRow * 2 : 0] = firstNewPoint;
        temp.splice(
          selectedRow ? selectedRow * 2 + 1 : 1,
          0,
          secondLastNewPoint
        );

        lastNewPoint = {
          ...lastNewPoint,
          time: editingArea.rtime,
          x: editingArea.rx,
          status: nextToLastPoint
            ? nextToLastPoint.status
            : lastNewPoint.status,
          eldData: nextToLastPoint
            ? nextToLastPoint.eldData
            : lastNewPoint.eldData,
          ...(providerMode ? {
            location: nextToLastPoint
              ? nextToLastPoint.location
              : lastNewPoint.location,
            note: nextToLastPoint
              ? nextToLastPoint.note
              : lastNewPoint.note,
            readOnly: nextToLastPoint
              ? nextToLastPoint.readOnly
              : lastNewPoint.readOnly,
          } : {}),
          stateModifier: nextToLastPoint
            ? nextToLastPoint.stateModifier
            : lastNewPoint.stateModifier,
          oilFieldWaiting: nextToLastPoint
            ? nextToLastPoint.oilFieldWaiting
            : lastNewPoint.oilFieldWaiting,
          y: nextToLastPoint ? nextToLastPoint.y : lastNewPoint.y
        };
        temp.splice(selectedRow ? selectedRow * 2 + 2 : 2, 0, lastNewPoint);
      } else if (
        currentStartingPoint.x !== firstNewPoint.x &&
        currentFinishingPoint.x === lastNewPoint.x
      ) {
        //No change in rx. y or lx is changed
        firstNewPoint = {
          ...firstNewPoint,
          time: editingArea.ltime,
          x: editingArea.lx,
          status: prevToFirstPoint
            ? prevToFirstPoint.status
            : firstNewPoint.status,
          stateModifier: prevToFirstPoint
            ? prevToFirstPoint.stateModifier
            : firstNewPoint.stateModifier,

          oilFieldWaiting: prevToFirstPoint
            ? prevToFirstPoint.oilFieldWaiting
            : firstNewPoint.oilFieldWaiting,
          y: prevToFirstPoint ? prevToFirstPoint.y : firstNewPoint.y
        };

        secondNewPoint = {
          ...secondNewPoint,
          status: currentStatus,
          stateModifier: currentStateModifier,
          oilFieldWaiting: currentOilFieldWaiting,
          y: editingArea.ly
        };
        secondLastNewPoint = {
          ...secondLastNewPoint,
          status: currentStatus,
          stateModifier: currentStateModifier,
          oilFieldWaiting: currentOilFieldWaiting,
          y: editingArea.ly
        };
        temp[selectedRow ? selectedRow * 2 + 1 : 1] = firstNewPoint;
        temp.splice(selectedRow ? selectedRow * 2 + 2 : 2, 0, secondNewPoint);
        temp.splice(
          selectedRow ? selectedRow * 2 + 3 : 3,
          0,
          secondLastNewPoint
        );
        temp[selectedRow ? selectedRow * 2 + 3 : 3] = lastNewPoint;
      }

      temp = removeUnwantedPoints(temp);
    }

    const result = resetDataList([...temp]);
    result[result.length - 1].minutes_to = 1440
    setDailyLogEntries({
      data: [...result],
      asArray: true
    });
  };

  const removeUnwantedPoints = (list) => {
    let result = [];
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.x > editingArea.lx && element.x < editingArea.rx) {
      } else {
        result.push(element);
      }
    }
    return result;
  };

  const handleTextEdit = (value, index, type) => {
    let graphValues = editedDataList.length
      ? [...editedDataList]
      : [...stepGraphPoints];
    graphValues[index][type] = value;
    graphValues[index + 1][type] = value;
    setEditedDataList([...graphValues]);
    setFinalListForUpdate([...graphValues]);
  };

  const handleTimeEdit = (value, index, type) => {
    let graphValues = editedDataList.length
      ? [...editedDataList]
      : [...stepGraphPoints];

    const isValid = validateTime(value);
    graphValues[index] = {
      ...graphValues[index],
      time: !isValid ? value : value + 'edited'
    };

    if (type === 'time_from') {
      graphValues[index].minutes_from = findMinutesFromTime(value)
      if (index > 0) {
        graphValues[index - 1].minutes_to = findMinutesFromTime(value)
      }
    }
    if (type === 'time_to') {
      graphValues[index].minutes_to = findMinutesFromTime(value)
      if (index < graphValues.length && graphValues[index + 1]) {
        graphValues[index + 1].minutes_from = findMinutesFromTime(value)
      }
    }
    if (graphValues.length) {
      const point = graphValues[index];
      if (index === 0) {
        point.time = startTime;
        point.minutes_from = 0
        if (graphValues[index + 1]) graphValues[index + 1].minutes_from = 0;
      } else if (index === editedDataList.length - 1) {
        point.time = endTime;
        point.minutes_to = 1440
        graphValues[index - 1].minutes_from = 1440;
      } else if (index % 2 === 0) {
        const prev = graphValues[index - 1];
        const prev2 = graphValues[index - 2];
        prev.time = point.time;
        prev.minutes_from = findMinutesFromTime(value)
        graphValues[index - 1].minutes_to = findMinutesFromTime(value)
        if (index > 1) {
          graphValues[index - 2].minutes_to = findMinutesFromTime(value)
        }
        graphValues[index - 1] = { ...prev };
        const nextTime = moment(prev?.time.replace('edited', ''), 'h:mma').toDate();
        const currentTime = moment(prev2?.time, 'h:mma').toDate();
        if (
          !udEdit && moment(nextTime).unix() < moment(currentTime).unix()
        ) {
          let v = document.getElementById(`timeToDailyLog${index - 1}`);
          v.classList.add('dailyLogDateError');
        } 
      } else if (index % 2 === 1) {
        const next = graphValues[index + 1];
        next.time = point.time;
        next.minutes_from = findMinutesFromTime(value)
        graphValues[index - 1].minutes_to = findMinutesFromTime(value)
        if (index > 1) {
          graphValues[index + 2].minutes_from = findMinutesFromTime(value)
        }
        graphValues[index + 1] = { ...next };
      }
      graphValues[index] = point;

      setEditedDataList(graphValues);
      setFinalListForUpdate(graphValues);
    }
    // }
  };

  const confirmEdit = (row, index) => {
    const item = editedDataList[index * 2];
    const next = editedDataList[index * 2 + 1];
    const y =
      getObjectKeyValue(
        yValues,
        'key',
        item.stateModifier?.value === 'OW'
          ? item.stateModifier.value
          : item.status.value,
        'y'
      ) - 3;
    setTimeout(
      () =>
        setEditingArea({
          ly: y,
          ry: y,
          ltime: item.time,
          rtime: next.time,
          lx:
            item.time === startTime
              ? leftTopCoordinate.x
              : findGraphPointsFromTime(item.time),
          rx:
            next.time === endTime
              ? svgWidth
              : findGraphPointsFromTime(next.time)
        }),
      500
    );
    setNewRow(0);
    setSelectedRow(index);
  };

  const handleOnEdit = (row, index) => {
    if (validateRow()) {
      setButtonUpdateStyle('button--update-normal');
      setButtonUpdateGraphStyle('button--disabled');
      const { temp } = checkIfEdited(editedDataList);
      const result = resetDataList([...temp]);
      setDailyLogEntries({
        data: [...result],
        asArray: true
      });
      updateDataList(() => {
        confirmEdit(row, index);
      });
    }
  };

  const showDeleteConfirmation = (row, index) => {
    setModalListObject({
      content:
        editedDataList.length === 2
          ? () => (
            <div className="successpopup">
              <div className="imageSection">
                <img
                  src={getImagePath(imageUrls.confirmIcon)}
                  alt="Minimum 1 row is required"
                />
              </div>
              <h2>{i18n.t('common.minimumRow')}</h2>
              <div className="deletePopupBtn">
                <Button
                  label={i18n.t('common.ok')}
                  onClick={() => {
                    setSelectedRow(-1);
                    closeModal();
                  }}
                ></Button>
              </div>
            </div>
          )
          : () => (
            <div className="successpopup">
              <div className="imageSection">
                <img
                  src={getImagePath(imageUrls.confirmIcon)}
                  alt="Are you sure"
                />
              </div>
              <h2>{i18n.t('common.sureConfirm')}</h2>
              <div className="deletePopupBtn">
                <div className="cancelBtn">
                  <Button
                    label={i18n.t('common.cancel')}
                    type={'reset'}
                    onClick={() => {
                      setSelectedRow(-1);
                      closeModal();
                    }}
                  ></Button>
                </div>
                <div className="deletebtn">
                  <Button
                    label={i18n.t('common.deletePopup')}
                    onClick={() => {
                      confirmDelete(row, index);
                    }}
                  ></Button>
                </div>
              </div>
            </div>
          )
    });
    clickedModal();
  };

  const handleOnDelete = (row, index) => {
    if (validateRow()) {
      updateDataList(() => {
        showDeleteConfirmation(row, index);
      });
    }
  };

  const closeModal = (event) => {
    setOpen(false);
  };

  const confirmDelete = (row, index) => {
    let points = [...editedDataList];
    points[index * 2].deleted = true;
    points[index * 2 + 1].deleted = true;
    const { temp } = checkIfEdited([...points]);
    setEditedDataList(temp);
    setFinalListForUpdate(temp);

    // temp.length && updateGraph(needUpdate, temp);
    setModalListObject({
      header: '',
      content: () => {
        return (
          <>
            <SuccessIcon />
            <div className="deletedRow">{i18n.t('Deleted!')}</div>
            <Button
              label={i18n.t('common.ok')}
              onClick={() => {
                closeModal();
              }}
            />
          </>
        );
      }
    });
  };

  const handleStatusChange = (value, index, type) => {
    if (selectedRow === -1 && validateRow()) {
      let points = editedDataList.length
        ? [...editedDataList]
        : [...stepGraphPoints];
      let firstPoint = {
        ...points[index],
        [type]: { ...value, edited: true },
        y: getObjectKeyValue(yValues, 'key', value.value, 'y') - 3
      };
      let secondPoint = {
        ...points[index + 1],
        [type]: value,
        y: getObjectKeyValue(yValues, 'key', value.value, 'y') - 3
      };
      if (
        enableEditOW &&
        value.value !== 'OFF' &&
        type === 'status' &&
        firstPoint?.stateModifier?.value === 'OW'
      ) {
        firstPoint.stateModifier = {
          label: i18n.t('common.pleaseSelect'),
          value: 'please',
          edited: true
        };
        secondPoint.stateModifier = { label: i18n.t('common.pleaseSelect'), value: 'please' };
      } else if (type === 'stateModifier' && value.value === 'OW') {
        firstPoint.status = { label: i18n.t('OFF_DUTY'), value: 'OFF', edited: true };
        secondPoint.status = { label: i18n.t('OFF_DUTY'), value: 'OFF' };
      }
      if (points[index].status.value == 'DR' && providerMode && firstPoint.status.value != 'DR') {
        firstPoint.readOnly = false
      }
      points[index] = firstPoint;
      points[index + 1] = secondPoint;

      setEditedDataList([...points]);
      setFinalListForUpdate([...points]);

      setButtonUpdateGraphStyle('button--update-normal');
    }
  };

  const handleSelectChange = (value, index, type) => {
    if ((selectedRow === -1 && validateRow() && !udEdit) || udEdit) {
      let points = editedDataList.length
        ? [...editedDataList]
        : [...stepGraphPoints];
      points[index][type] = { ...value };
      points[index + 1][type] = { ...value };
      setEditedDataList([...points]);
      setFinalListForUpdate([...points]);
    }
  };

  const validateTime = (time) => {
    time = time?.replace('edited', '');
    const isValid = time?.match(/(10|11|12|[0-9]):[0-5][0-9][a|p]m/);
    return isValid?.length ? true : false;
  };

  const validateRow = () => {
    let elements = document.getElementsByClassName('time_wrapper');
    for (let i = 0, len = elements.length; i < len; i++) {
      elements[i].classList.remove('dailyLogDateError');
    }
    for (let index = 0; index < editedDataList.length; index++) {
      const element = editedDataList[index];
      const isValid = validateTime(element.time);
      if (!isValid) {
        return false;
      }
      if (index !== editedDataList.length - 1) {
        let v = document.getElementById(`timeToDailyLog${index + 1}`);
        const nextTime1 = editedDataList[index + 1]['time'].replace(
          'edited',
          ''
        );

        const current1 = editedDataList[index]['time'].replace('edited', '');

        const nextTime = moment(nextTime1, 'h:mma').toDate();
        const currentTime = moment(current1, 'h:mma').toDate();

        // if (index !== 0 && index !== editedDataList.length - 2) {
        if (
          !udEdit &&
          moment(nextTime).unix() < moment(currentTime).unix() &&
          index + 1 !== editedDataList.length - 1
        ) {
          v.classList.add('dailyLogDateError');
          return false;
        }
      }
    }
    return true;
  };

  const getRowInfo = (list, rows) => {
    let row = {};
    for (let index = 0; index < list.length; index++) {
      const point = list[index];
      const time = point?.time?.includes('edited') > 0
        ? point?.time?.replace('edited', '')
        : point?.time;
      const previous = list[index - 1];
      const next = list[index + 1];
      if (index % 2 === 0) {
        if (point?.noEdit) {
          row.noEdit = true;
        }
        row.readOnly = point?.readOnly;
        row.driverId = !viewOnly ? (
          <Select
            onChange={(value, type) => {
              if ((providerMode && point.eldData) || (!providerMode && !point?.readOnly && !viewOnly)) {
                handleSelectChange(value, index, 'driverId');
              }
              udEdit &&
                updateField({
                  data: value,
                  field: 'driverId',
                  id: point.id
                });
            }}
            placeholder={i18n.t('common.pleaseSelect')}
            value={point?.driverId || ''}
            suggestions={udEdit ? drivers : [assignedDriver]}
            disabled={providerMode && point.eldData ? false : providerMode && !point.eldData ? true :
              ((point?.udReadOnly && udEdit) ||
                (point?.readOnly && !udEdit) ||
                viewOnly ||
                selectedRow > -1)
            }
          />
        ) : point?.driverId?.value === 0 ? '-' : point?.driverId?.label;
        row.stateModifier = !viewOnly ? (
          <Select
            onChange={(value) => {
              if ((providerMode && point.eldData) || (!providerMode && !point?.readOnly && !viewOnly)) {
                handleStatusChange(value, index, 'stateModifier');
              }
            }}
            disabled={providerMode && point.eldData ? false : providerMode && !point.eldData ? true : (point?.readOnly || viewOnly || selectedRow > -1)}
            value={
              point?.stateModifier || {
                label: i18n.t('common.pleaseSelect'),
                value: 'please'
              }
            }
            placeholder={i18n.t('common.pleaseSelect')}
            suggestions={
              window.$environment.SERVICE_PROVIDER === 'clutch'
                ? enableEditOW && point?.status.value === 'OFF'
                  ? [
                    {
                      label: 'Yes',
                      value: 'OW'
                    },
                    {
                      label: 'No',
                      value: 'please'
                    }
                  ]
                  : [{ label: 'No', value: 'please' }]
                : enableEditOW && point?.status.value === 'OFF'
                  ? [
                    {
                      label: i18n.t('common.pleaseSelect'),
                      value: 'please'
                    },
                    {
                      label: i18n.t('OIL_FIELD_WAITING'),
                      value: 'OW'
                    }
                  ]
                  : [{ label: i18n.t('common.pleaseSelect'), value: 'please' }]
            }
          />
        ) : point?.stateModifier?.value === 'please' ? '-' : point.stateModifier.label;
        row.location = (
          <Input
            value={point?.location || ''}
            style={{ color: 'white' }}
            handleOnChange={(value) => {
              if (
                (((selectedRow === -1 &&
                  validateRow() &&
                  !point?.readOnly &&
                  !udEdit) ||
                  udEdit) && !providerMode) || (providerMode && point.eldData)
              ) {
                handleTextEdit(value, index, 'location');
              }
            }}
            disabled={providerMode && point.eldData ? false : providerMode && !point.eldData ? true : (
              (point?.udReadOnly && udEdit) ||
              (point?.readOnly && !udEdit) ||
              viewOnly ||
              selectedRow > -1
            )}
            type="text"
            onBlur={(event) => {
              udEdit &&
                updateField({
                  data: event,
                  field: 'location',
                  id: point.id
                });
            }}
          />
        );
        row.note = (
          <>
            <Input
              value={point?.note || ''}
              style={{ color: 'white' }}
              handleOnChange={(value, event) => {
                if (
                  (((selectedRow === -1 &&
                    validateRow() &&
                    !point?.readOnly &&
                    !udEdit) ||
                    udEdit) && !providerMode) || (providerMode && point.eldData)
                ) {
                  handleTextEdit(value, index, 'note');
                } else {
                  event.preventDefault();
                }
              }}
              type="text"
              disabled={providerMode && point.eldData ? false : providerMode && !point.eldData ? true :
                ((point?.udReadOnly && udEdit) ||
                  (point?.readOnly && !udEdit) ||
                  viewOnly ||
                  selectedRow > -1)
              }
              onBlur={(event) => {
                udEdit &&
                  updateField({
                    data: event,
                    field: 'note',
                    id: point.id
                  });
              }}
            />
            {/* This may be uncommented in future. */}
            {/* {((!point?.readOnly && !udEdit) || udEdit) &&
              ((!point.udReadOnly && udEdit) || !udEdit) &&
              point?.note?.length !== 0 &&
              point?.note?.length < 4 && (
                <Message
                  type="error"
                  message="Please enter atleast 4 characters in notes"
                />
              )} */}
          </>
        );
        row.status = (
          <>
            {!viewOnly ? (<Select
              onChange={(value) => {
                if ((providerMode && point.eldData) || (!providerMode && !point?.readOnly && !viewOnly)) {
                  handleStatusChange(value, index, 'status');
                }
              }}
              name="status"
              value={getFilterLocaleValues([point?.status || ''], i18n)}
              suggestions={getFilterLocaleValues(statusList, i18n)}
              disabled={providerMode && point.eldData ? false : providerMode && !point.eldData ? true :
                (point?.readOnly || viewOnly || selectedRow > -1 || udEdit)
              }
            />) :
              point?.status?.label ? point.status.label : '-'}
          </>
        );
        let minHr = 0;
        let minMin = 0;
        let maxHr = 23;
        let maxMin = 59;
        if (list[index + 2] && list[index + 2].readOnly) {
          maxHr = list[index + 1].time.split(':')[1].includes('am')
            ? parseInt(list[index + 1].time.split(':')[0], 10) === 12
              ? 0
              : list[index + 1].time.split(':')[0]
            : parseInt(list[index + 1].time.split(':')[0], 10) === 12
              ? 12
              : parseInt(list[index + 1].time.split(':')[0], 10) + 12;
          maxMin = parseInt(
            list[index + 1].time.split(':')[1].slice(0, -2),
            10
          );
        }
        if (providerMode && list[index + 1] && list[index + 1].minutes_to) {
          maxHr = Math.floor(list[index + 1].minutes_to / 60)
          maxMin = Math.floor(list[index + 1].minutes_to % 60)
        }
        if (providerMode && list[index - 1] && list[index + 1].minutes_from) {
          minHr = Math.floor(list[index - 1].minutes_from / 60)
          minMin = Math.floor(list[index - 1].minutes_from % 60)
        }

        if (providerMode && list[index - 1] && list[index + 1].minutes_from && list[index - 2] && list[index - 2].minutes_from) {
          minHr = Math.floor(list[index - 2].minutes_from / 60)
          minMin = Math.floor(list[index - 2].minutes_from % 60)
        }

        row.time_from = (
          <div
            id={`timeToDailyLog${index}`}
            style={{
              color: 'white',
              border: validateTime(point?.time) ? '0px' : 'solid 2px red'
            }}
            className="time_wrapper"
          >
            <DatePicker
              onChange={(data) => {
                if (data) {
                  let elements = document.getElementsByClassName('time_wrapper');
                  for (let i = 0, len = elements.length; i < len; i++) {
                    elements[i].classList.remove('dailyLogDateError');
                  }
                  let v = document.getElementById(`timeToDailyLog${index}`);
                  const nextTime1 =
                    list[index + 1]['time'].includes('edited') > 0
                      ? list[index + 1]['time'].replace('edited', '')
                      : list[index + 1]['time'];

                  const nextTime = moment(nextTime1, 'h:mma').toDate();

                  if (index !== 0 && index !== list.length - 2) {
                    if (
                      !udEdit &&
                      moment(nextTime).unix() < moment(data).unix()
                    ) {
                      v.classList.add('dailyLogDateError');
                    } else {
                      v.classList.contains('dailyLogDateError') &&
                        v.classList.remove('dailyLogDateError');
                    }
                  }
                  if ((!point?.readOnly && !providerMode) || (providerMode && point.eldData)) {
                    const time = moment(data).format('h:mma');

                    handleTimeEdit(time, index, 'time_from');
                  }
                }
              }}
              // value={
              //   point?.time.includes('edited') > 0
              //     ? point?.time.replace('edited', '')
              //     : point?.time
              // }
              showPopperArrow={false}
              // locale={getDateLocale()}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={(window.$environment.SERVICE_PROVIDER === 'cyntrx' || window.$environment.SERVICE_PROVIDER === 'webfleet') ? 1 : 5}
              timeCaption=""
              selected={time ? moment(time, 'h:mma').toDate() : null}
              dateFormat="h:mma"
              disabled={providerMode && point.eldData ? false : providerMode && !point.eldData ? true :
                ((previous && previous.readOnly) ||
                  point?.readOnly ||
                  viewOnly ||
                  selectedRow > -1 ||
                  udEdit)
              }
              minTime={moment().set({ hour: minHr, minute: minMin }).toDate()}
              maxTime={moment().set({ hour: maxHr, minute: maxMin }).toDate()}
            />
          </div>
        );
      } else {
        let minHr = 0;
        let minMin = 0;
        let maxHr = 23;
        let maxMin = 59;
        if (list[index].minutes_from) {
          minHr = Math.floor(list[index].minutes_from / 60)
          minMin = Math.floor(list[index].minutes_from % 60)
        }
        if (providerMode && list[index + 1] && list[index + 1].minutes_to) {
          maxHr = Math.floor(list[index + 1].minutes_to / 60)
          maxMin = Math.floor(list[index + 1].minutes_to % 60)
        }
        if (list[index - 2] && list[index - 2].readOnly) {
          minHr = list[index - 1].time.split(':')[1]?.includes('am')
            ? parseInt(list[index - 1].time.split(':')[0], 10) === 12
              ? 0
              : list[index - 1].time.split(':')[0]
            : parseInt(list[index - 1].time.split(':')[0], 10) === 12
              ? 12
              : parseInt(list[index - 1].time.split(':')[0], 10) + 12;
          minMin = parseInt(
            list[index - 1].time.split(':')[1]?.slice(0, -2),
            10
          );
        }
        row.time_to = (
          <div
            id={`timeToDailyLog${index}`}
            style={{
              color: 'white',
              border: validateTime(point?.time) ? '0px' : 'solid 2px red'
            }}
            className="time_wrapper"
          >
            <DatePicker
              onChange={(data) => {
                if (data) {
                  let elements = document.getElementsByClassName('time_wrapper');
                  for (let i = 0, len = elements.length; i < len; i++) {
                    elements[i].classList.remove('dailyLogDateError');
                  }
                  let v = document.getElementById(`timeToDailyLog${index}`);
                  const prevTime1 =
                    list[index - 1]['time'].includes('edited') > 0
                      ? list[index - 1]['time'].replace('edited', '')
                      : list[index - 1]['time'];
                  const prevTime = moment(prevTime1, 'h:mma').toDate();

                  if (index !== list.length - 1) {
                    if (
                      !udEdit &&
                      moment(prevTime).unix() > moment(data).unix()
                    ) {
                      v.classList.add('dailyLogDateError');
                    } else {
                      v.classList.contains('dailyLogDateError') &&
                        v.classList.remove('dailyLogDateError');
                    }
                  }
                  if ((!point?.readOnly && !providerMode) || (providerMode && point.eldData)) {
                    const time = moment(data).format('h:mma');
                    handleTimeEdit(time, index, 'time_to');
                  }
                }
              }}
              // locale={getDateLocale()}
              as={DatePicker}
              // value={
              //   point?.time.includes('edited') > 0
              //     ? point.time.replace('edited', '')
              //     : point.time
              // }
              showPopperArrow={false}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={(window.$environment.SERVICE_PROVIDER === 'cyntrx' || window.$environment.SERVICE_PROVIDER === 'webfleet') ? 1 : 5}
              timeCaption=""
              selected={time ? moment(time, 'h:mma').toDate() : null}
              dateFormat="h:mma"
              disabled={providerMode && point.eldData ? false : providerMode && !point.eldData ? true :
                ((next && next.readOnly) ||
                  point?.readOnly ||
                  viewOnly ||
                  selectedRow > -1 ||
                  udEdit)
              }
              minTime={moment().set({ hour: minHr, minute: minMin }).toDate()}
              maxTime={moment().set({ hour: maxHr, minute: maxMin }).toDate()}
            />
          </div>
        );
        rows.push(row);
        row = {};
      }
    }
  };

  const generateStatusRows = () => {
    const rows = [];
    getRowInfo(
      // (udEdit && setFinalListForUpdate) ||
      editedDataList.length && editedDataList,
      rows
    );

    return rows;
  };

  const clickedModal = (e) => {
    setOpen(true);
  };

  const addNewRowToList = (list) => {
    if (!list[list?.length - 1]?.readOnly && !providerMode) {
      setNewRow(newRow + 1);
      const points = list.length ? [...list] : [...stepGraphPoints];
      //Need confirmation
      const lastIndex = editedDataList.length - 1;
      let point = {
        ...points[lastIndex],
        x: '',
        time: ''
      };
      points[lastIndex] = { ...point };
      const firstPoint = {
        stateModifier: {
          label: i18n.t('common.pleaseSelect'),
          value: 'please'
        },
        time: '',
        location: null,
        driverId: { ...assignedDriver },
        y: getObjectKeyValue(yValues, 'key', 'OFF', 'y') - 3,
        status: { label: i18n.t('OFF_DUTY'), value: 'OFF' },
        x: '',
        readOnly: false,
        noEdit: true,
        minutes_from: 0,
        minutes_to: 1440,
      };
      const secondPoint = {
        stateModifier: {
          label: i18n.t('common.pleaseSelect'),
          value: 'please'
        },
        location: '',
        driverId: { ...assignedDriver },
        x: svgWidth,
        y: getObjectKeyValue(yValues, 'key', 'OFF', 'y') - 3,
        status: { label: i18n.t('OFF_DUTY'), value: 'OFF' },
        time: endTime,
        readOnly: false,
        noEdit: true,
        minutes_from: 0,
        minutes_to: 1440,
      };
      points.push({ ...firstPoint });
      points.push({ ...secondPoint });

      setEditedDataList([...points]);
      setFinalListForUpdate([...points]);
    }
  };

  const addNewRow = () => {
    selectedRow < 0 && updateDataList(() => addNewRowToList(editedDataList));
  };

  const updateDataList = (callback) => {
    if (validateRow()) {
      callback();
    }
  };

  const editStatus = (event) => {
    if (selectedRow !== -1 && canEditRowByGraph) {
      yValues.map((y) => {
        const node = document.getElementById(y.key);
        node.className = node.className.replace('active', '');
      });
      const className = event.target.offsetParent.className;
      event.target.offsetParent.className = className.includes('active')
        ? event.target.offsetParent.className
        : event.target.offsetParent.className + ' active';

      changeStatusLine(event);
    }
  };
  const columns =
    enableEditOW && !udEdit
      ? [
        {
          id: 'status',
          numeric: false,
          disablePadding: true,
          label: i18n.t('logs.status'),
          needSorting: false
        },
        {
          id: 'stateModifier',
          numeric: false,
          disablePadding: true,
          label:
            window.$environment.SERVICE_PROVIDER === 'clutch'
              ? i18n.t('logs.oildFieldWaiting')
              : i18n.t('logs.stateModifier'),
          placeholder: i18n.t('common.pleaseSelect'),
          needSorting: false
        },
        {
          id: 'time_from',
          numeric: true,
          disablePadding: false,
          label: i18n.t('logs.timeFrom'),
          needSorting: false
        },
        {
          id: 'time_to',
          numeric: true,
          disablePadding: false,
          label: i18n.t('logs.timeTo'),
          needSorting: false
        },
        {
          id: 'location',
          numeric: true,
          disablePadding: false,
          label: i18n.t('logs.location'),
          needSorting: false
        },
        {
          id: 'driverId',
          numeric: true,
          disablePadding: false,
          label: i18n.t('logs.assignedDriver'),
          needSorting: false
        },
        {
          id: 'note',
          numeric: true,
          disablePadding: false,
          label: i18n.t('logs.notes'),
          needSorting: false
        },
        {
          id: 'actions'
        }
        // {
        //   id: 'actions'
        // }
      ]
      : [
        {
          id: 'status',
          numeric: false,
          disablePadding: true,
          label: i18n.t('logs.status'),
          needSorting: false
        },

        {
          id: 'time_from',
          numeric: true,
          disablePadding: false,
          label: i18n.t('logs.timeFrom'),
          needSorting: false
        },
        {
          id: 'time_to',
          numeric: true,
          disablePadding: false,
          label: i18n.t('logs.timeTo'),
          needSorting: false
        },
        {
          id: 'location',
          numeric: true,
          disablePadding: false,
          label: i18n.t('logs.location'),
          needSorting: false
        },
        {
          id: 'driverId',
          numeric: true,
          disablePadding: false,
          label: i18n.t('logs.assignedDriver'),
          needSorting: false
        },
        {
          id: 'note',
          numeric: true,
          disablePadding: false,
          label: i18n.t('logs.notes'),
          needSorting: false
        },
        {
          id: 'actions'
        }
        // {
        //   id: 'actions'
        // }
      ];
  const [showTopMessage, setShowTopMessage] = useState(false);

  const toggleProvider = () => {
    setOpen(true)
    setModalListObject({
      content: () => (
        <div className="successpopup">
          <div className="imageSection">
            <img
              src={getImagePath(imageUrls.confirmIcon)}
              alt="Are you sure"
            />
          </div>
          <h2>{i18n.t(!providerMode ? 'Turning ON provider mode will allow you to edit ELD entries, but all other changes made will be lost' : 'Turning off provider mode will lead to the loss of edited ELD entries')}</h2>
          <div className="deletePopupBtn">
            <div className="cancelBtn">
              <Button
                label={i18n.t('common.cancel')}
                type={'reset'}
                onClick={() => {
                  closeModal();
                }}
              ></Button>
            </div>
            <div className="deletebtn">
              <Button
                label={i18n.t('Yes, proceed!')}
                onClick={() => {
                  // setDataList(originalData.dailyLogEntries)
                  getDailyLogDetails({
                    apiId: 'getDailyLogDetailsAPI',
                    data: { id: match.id },
                    providerMode: !providerMode,
                    udEdit: false
                  })
                  setProviderMode(prev => { return !prev })
                  closeModal();
                }}
              ></Button>
            </div>
          </div>
        </div>
      )
    });
  }

  const checkEldEntries = () => {
    return originalData?.dailyLogEntries?.some(ele => { return ele.readOnly && ele.status == 'DRIVING' })
  }
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      maxWidth: 220,
      fontSize: "17px",
    },
  }))(Tooltip);

  return (
    <Styled.ChartWrapper>
      <div className="quickFilter"></div>
      {hasEnabledFeature('chartEnabled') && !udEdit && (
        <>
          {selectedRow > -1 && showTopMessage && (
            <div className="editmodeNotification" style={{ color: 'white' }}>
              You are in edit mode. Please click update events before attempting
              to make a new edit.
            </div>
          )}
          <div id="chartContainer" className="svg-container"></div>
          {!viewOnly ? (
            <div className={isAdmin ? 'updateEventsectionWrapper' : ''}>
              {isAdmin ? <div className={`providerWrapper`}>
                <div className={`${checkEldEntries() ? '' : 'disableProvider'} providerWrapper`}>
                  <Switch
                    checked={providerMode}
                    onChange={toggleProvider}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                  <div onClick={toggleProvider} className='providerLabel'>Provider Mode</div>
                </div>

                {checkEldEntries() ? null : <HtmlTooltip id="custom-tool" title={"Provider mode is disabled due to the absence of ELD entries in this log"} arrow placement='right'>
                  <ExclamationCircleOutlined className='infoIcon' />
                </HtmlTooltip>}
              </div> : null}
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                className="updateEventsection"
              >
                {yValues.map((y) => (
                  <React.Fragment key={y.text}>
                    <Button
                      className={'button-status ' + buttonStatusStyle}
                      dataValue={y.y.toString()}
                      label={i18n.t(y.text)}
                      id={y.key}
                      onClick={editStatus}
                    />
                  </React.Fragment>
                ))}
                <Box id="update_graph">
                  <Button
                    className={'button-update ' + buttonUpdateStyle}
                    label={i18n.t('logs.UpdateEvents')}
                    onClick={() => {
                      const { needUpdate, temp } = checkIfEdited(editedDataList);
                      selectedRow > -1 && updateGraph(needUpdate, temp);
                    }}
                  />
                </Box>
                <Button
                  className={'button-update ' + buttonUpdateGraphStyle}
                  label={i18n.t('logs.UpdateGraph')}
                  id="updategraph"
                  onClick={() => {
                    const { needUpdate, temp } = checkIfEdited(editedDataList);

                    selectedRow === -1 && updateGraph(needUpdate, temp);
                  }}
                />
              </Box>
            </div>

          ) : null}
        </>
      )}
      <Styled.StatusModifier>
        <Table
          type="client"
          title=""
          columns={columns}
          data={generateStatusRows()}
          rowSelection={() => {
            const isTimeValid = validateRow();
            if (selectedRow > -1 || !isTimeValid) return;
          }}
          actions={
            viewOnly || udEdit
              ? []
              : [
                {
                  icon: (row, index, disabled) => {
                    const previous = editedDataList[index * 2 - 1];
                    const current = editedDataList[index * 2];
                    const next = editedDataList[index * 2 + 2];
                    if (
                      (providerMode && !current.eldData) || (!providerMode && (previous?.readOnly ||
                        current.readOnly ||
                        (next && next.readOnly)))
                    ) {
                      return null;
                    } else {
                      return (
                        <Icon
                          src={getImagePath('icons/delete.png')}
                          style={{
                            width: '20px',
                            height: '20px',
                            overflow: 'visible'
                          }}
                          onClick={() =>
                            !disabled &&
                            selectedRow < 0 &&
                            handleOnDelete(row, index)
                          }
                        />
                      );
                    }
                  }
                },
                {
                  icon: (row, index, disabled) => {
                    const previous = editedDataList[index * 2 - 1];
                    const current = editedDataList[index * 2];
                    const next = editedDataList[index * 2 + 2];
                    return row.noEdit ||
                      !hasEnabledFeature('chartEnabled') ||
                      window.$environment.SERVICE_PROVIDER ===
                      'spireon' ? null : (
                      <Icon
                        src={
                          (providerMode && !current.eldData) || (!providerMode && (previous?.readOnly ||
                            current.readOnly ||
                            (next && next.readOnly)))
                            ? getImagePath('icons/notFull.png')
                            : getImagePath('icons/edit.png')
                        }
                        style={{
                          width: '20px',
                          height: '20px',
                          overflow: 'visible'
                        }}
                        onClick={() => {
                          setShowTopMessage(
                            (providerMode && !current.eldData) || (!providerMode && (previous?.readOnly ||
                              current?.readOnly ||
                              next?.readOnly))
                              ? false
                              : true
                          );
                          setCanEditRowByGraph(
                            (providerMode && !current.eldData) || (!providerMode && (previous?.readOnly ||
                              current?.readOnly ||
                              next?.readOnly))
                              ? false
                              : true
                          );
                          if ((providerMode && current.eldData) ||
                            (!providerMode && ((!disabled && selectedRow === -1) ||
                              (!disabled &&
                                (previous?.readOnly ||
                                  current?.readOnly ||
                                  next?.readOnly))))
                          )
                            handleOnEdit(row, index);
                        }}
                        className={
                          (providerMode && !current.eldData) || (!providerMode && (previous?.readOnly ||
                            current.readOnly ||
                            (next && next.readOnly)))
                            ? 'viewIconWrapper'
                            : 'iconContainer'
                        }
                      />
                    );
                  }
                }
              ]
          }
          i18n={i18n}
          noPagination
          noTableActions
        />
      </Styled.StatusModifier>
      {!viewOnly && !udEdit && (
        <Box id="addNewRow">
          <Button
            label={i18n.t('logs.addNewRow')}
            tooltipTxt={selectedRow >= 0 ? 'Update Events before adding new row' : ''}
            onClick={addNewRow}
            className={`button-add ${selectedRow >= 0 || providerMode ? 'btn-disabled' : ''}`}
          />
        </Box>
      )}

      <Modal.default
        open={open}
        setOpen={setOpen}
        data={ModalListObject}
        isSuccess
      />
    </Styled.ChartWrapper>
  );
};

export default DailyLog;
