import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import React, { lazy, useState } from 'react';

import GaugeChart from '@/components/UI/Graph/GaugeChart/Base';
import { pageUrls } from '@/config/constants/keys';
import { hasRestrictedRole } from '@/utils/utils';
import { Divider, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForward';
import InsightsIcon from '@material-ui/icons/Timeline';
import { debounce, isEmpty, isObject } from 'lodash';
import moment from 'moment';
import 'rc-tooltip/assets/bootstrap.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DonutGraphInfo from './DonutGraphInfo';



const layout = window?.$environment?.CURRENT_LAYOUT;
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Label = lazy(() => import(`@/components/UI/Label/${layout}`));
const MainFilter = lazy(() => import(`./MainFilter`));
const InfoCard = lazy(() => import(`./InfoCard`));
const RangeInfo = lazy(() => import(`./RangeInfo`));
const ToolTip = lazy(() => import(`./ToolTip`));
const FleetScore = lazy(() => import(`./FleetScoreInfo`));



const fleetIconMapping = {
  'Tractor Day Cab': 'tractorDayCab',
  'Tractor Sleeper': 'tractorSleeper',
  Bobtail: 'bobTail',
  'Straight Truck': 'straightTruck',
  'Box Truck': 'boxTruck',
  Pickup: 'pickup',
  'Private Passenger': 'privatePassenger',
  Vacuum: 'vacuumTrailer',
  Van: 'van',
  'Other Trailers': 'otherTrailer',
  Flatbed: 'flatBed',
  'Dry Van': 'dryVan',
  Reefer: 'reeferTrailer',
  Tanker: 'tankerTrailer',
  Dump: 'dump',
  Pneumatic: 'pneumaticTrailer',
  'Other Vehicles': 'otherVehicle',
  'Other': 'otherTrailer',
  'infoTruck': 'infoTruck'

};

const scoreCardItemDescription = {
  fleet: 'Fleet',
  address: 'Address',
  dotNumber: 'DOT Number',
  xDate: 'Insurance expiry date',
  reportDate: 'Report date',
  operatingTerritory: {
    overageStatus: 'Percentage of radius violations in each radius boundaries',
    isftaStatus: 'Mileage of vehicles per state'
  },
  quickStatus: {
    fleetManagementScore: 'A look at your company',
    vehicleInspectionScore: 'Quick look at your dvir history',
    consistentEquipment: 'How long you have used the same vehicles',
    vehicleComposition: 'Type of vehicles in your fleet',
    driverTurnOver: 'This is calculated by dividing employment length by the total number of drivers',
    driverTenure: 'This is calculated by the total number of drivers hired in the current year (Decided based on the hire date) divided by the total number of drivers'
  },
  fleetManagement: 'Calculated based on the number of driving events. The score represents safe driving behavior, with fewer events leading to a higher score. Method: 0.6 * Driver Behavior Score',
  radiusCompliance: 'List of radius compliance violations'
}

const Component = ({ Styled, scoreCard, companyId, fetchDetails, ...rest }) => {
  const history = useHistory();
  const [filterValue, setFilterValue] = useState();
  const handleFilterChange = (each) => {
    setFilterValue(each);
    fetchDetails({ data: { id: companyId, ...(each?.value === "YEARLY" ? { isYearly: true } : {}) }, apiId: 'getData' });
  }

  const titleToolTip = (title = '', info = '', className = 'subHeadTooltip') => (
    <div>
      <Label variant="body1" className={`statsHeading ${className}`}>
        {title}
        <ToolTip title={info} arrow placement='top'>
          <InfoOutlinedIcon />
        </ToolTip>
      </Label>
    </div>
  );

  const graphContainer = (title = '', data, small = false, info = '', isAccident= false) => (
    <div className='graph-container'>{small ? <Label className="titleSmall">{title}
      {/* <ToolTip title={info} arrow placement='top'>
        <InfoOutlinedIcon />
      </ToolTip> */}
    </Label> : <SubHeader
      variant="h1"
      className="statsHeading"
      text={title}
    />}
      <div className={`fleet-score-graph ${small ? 'smallGraph' : ''}`}>
        <div className='div60'> <DonutGraphInfo backgroundColor={isAccident ? ['green', 'yellow', 'orange', 'red'] : ['green', 'yellow', 'red']} data={data?.chartData} centerTextData={{
          count: data?.totalScore,
          text: 'Actual Score'
        }} small /></div>
        <div className='div40'><RangeInfo smallGraph={small} isAccident={isAccident} /></div>
      </div>
    </div>
  );

  const lGraphContainer = (title = '', data, small = false, info = '', cardInfo = '') => (
    <div><SubHeader
      variant="h1"
      className="statsHeading"
      text={titleToolTip(title, info)}
    />
      <div className='infoPosition'>
        <div className='card'>
          <span className='text'>{cardInfo}</span>
          <span className='count'>{data?.totalDays}</span>
        </div>
      </div>
      <div className={`fleet-score-graph2`}>
        <div className='div60'> <DonutGraphInfo isUnPoweredViolation centerTextData={{
          count: data?.totalScore,
          text: 'Actual Score'
        }} data={data?.chartData} /></div>
        <Styled.DIV40 isBottomAlign><RangeInfo isUnPoweredViolation smallGraph={small} /></Styled.DIV40>
      </div>
    </div>
  );

  const fleetScore = (
    <div className='grid-item'>
      <SubHeader
        variant="h1"
        className="statsHeading"
        text={'Fleet Score'}
      />
        <div className='fleet-score-graph3'>
          <div className='div60'><GaugeChart value={scoreCard?.fleetScore} textClassName="totalScore" /></div>
          <div className='div40'><RangeInfo fleetScore /></div>
        </div>
        <div style={{
              width: '77%',
              textAlign: 'center',
        }}>
          <Label className="label" variant="h1">{scoreCard?.fleetScore}</Label>
          </div>
        <Divider className='divider-style' />
        <FleetScore scoreCard={scoreCard} />
    </div>
  );

  const handleClick = debounce((event) => {
    if (!hasRestrictedRole(['FLMANAGER', 'ADMIN'])) {
        const endTime = moment().valueOf();
        const startTime = filterValue?.value === "YEARLY" 
            ? moment().subtract(1, 'year').valueOf() 
            : moment().subtract(1, 'month').valueOf();
        history.push(`${pageUrls?.cameraList}?endTime=${endTime}&startTime=${startTime}`);
    }
}, 600);

  const driverBehaviour = (
    <div className='grid-item'>
      <SubHeader
        variant="h1"
        className="statsHeading title"
        text={titleToolTip('Driver Behavior', (
          <p>
          Calculated based on the number of driving events across all trucks.<br /><br />
          Method: 100 - (Sum of Events of all trucks / (Total Working Days * Total Trucks)) * 100.<br /><br />
          The pie chart shows the number of trucks grouped by their individual performance percentages, indicating how many trucks fall within each defined range.
                </p>
        ))}
      />
      <div className='subCharts'>
        <div className="part1">
          <div>
            <div className='fleet-score-graph'>
              <div className='div60'><DonutGraphInfo centerTextData={{
                count: scoreCard?.totalDrivingBehaviorScore,
                text: 'Actual Score'
              }} onClick={() => {}} hideHover data={scoreCard?.totalDrivingBehaviorData} backgroundColor={['#eaeaea', '#cecece', '#0097e6']} /></div>
              <Styled.DIV40 isBottomAlign><RangeInfo isDriver smallGraph={true} /></Styled.DIV40>
            </div>
          </div>
        </div>
        <div className="part2">
          <div className='row2'>
            <div className='col1'>
            {graphContainer('Distracted Driving', scoreCard?.distractedDriving, true, ``)}
            </div>
          </div>
          <div className='row1'>
            <div className='col1'>
            {graphContainer('Accident', scoreCard?.accident, true, ``, true)}
            </div>
            <div className='col2'>
            {graphContainer('Speeding', scoreCard?.speeding, true, ``)}
              </div>
          </div>
        </div>
      </div>
      {!hasRestrictedRole(['UNDER_WRITER']) && <Grid style={{ display: "flex" }} justify="flex-end">
        <Button variant="text" size="small" onClick={(event) => {
          handleClick(event)
        }} style={{
              background: 'transparent',
        }} endIcon={<ArrowForwardIosIcon />}>More Info</Button>
      </Grid>}
    </div>
  );

  const unpoweredDays = (
    <div className='grid-item2 topMargin'>
      <div className="part1">{lGraphContainer('Unpowered Days', scoreCard?.unpoweredDays, true, 
      
      (
        <p>
        Calculated based on the number of days trucks were unpowered.<br /><br />
Method: 100 - (Sum of each truck's Unpowered Days / (Total Working Days * Total Trucks)) * 100.<br /><br />
The pie chart shows the number of trucks grouped by their individual performance percentages, indicating how many trucks fall within each defined range.
              </p>
      ), 'The cumulative number of unpowered days across all trucks in the fleet')}</div>
      <Divider className='divider-style2' orientation="vertical" />
      <div className="part2">{lGraphContainer('Radius Violations', scoreCard?.radiusViolations, true,  
      (
        <p>
        Calculated based on the number of days trucks violated the predefined radius.<br /><br />
Method: 100 - (Sum of each truck's Violation Days / (Total Working Days * Total Trucks)) * 100.<br /><br />
The pie chart shows the number of trucks grouped by their individual performance percentages, indicating how many trucks fall within each defined range.

              </p>
      ), 'The cumulative number of radius violation days across all trucks in the fleet')}</div>
    </div>
  );

  return isEmpty(scoreCard) && isObject(scoreCard) ? (
    <Styled.MainWrapper>
      <Styled.FilterWrapper>
        <div className='filter'><MainFilter onChange={handleFilterChange} /></div>
      </Styled.FilterWrapper>
      <Styled.Wrapper widthOfProgress={90}>
        <div className='noDataFound'>
            <InsightsIcon color="secondary" className='icon' />
            <Label variant="h1" className="text">No  Data  Available !</Label>
        </div>
      </Styled.Wrapper>
    </Styled.MainWrapper>
  ) : (
    <Styled.MainWrapper>
      <Styled.FilterWrapper>
        <div className='filter'><MainFilter onChange={handleFilterChange} /></div>
        <div className='info'>
          <InfoCard fleetIconMapping={fleetIconMapping} companyName={scoreCard?.companyName} totalCount={scoreCard?.totalVehicleCount} />
        </div>
      </Styled.FilterWrapper>
      <Styled.Wrapper widthOfProgress={90}>
        <div className="left">
          {fleetScore}
        </div>
        <div className="right">
          {driverBehaviour}
          {unpoweredDays}
        </div>
      </Styled.Wrapper>
    </Styled.MainWrapper>
  )
};

export default Component;

Component.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  scoreCard: PropTypes.object.isRequired
};
